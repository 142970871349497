import type { CSSProperties } from "react";

type SageProps = {
  style?: CSSProperties;
};

const Sage = ({ style }: SageProps) => {
  return (
    <svg width="130" height="31" fill="none" viewBox="0 0 130 31" style={style}>
      <path
        fill="url(#paint0_linear_352_313)"
        d="M64.541 1.058a.5.5 0 01.918 0l2.682 6.186a.5.5 0 00.411.299l6.713.64a.5.5 0 01.283.872l-5.054 4.463a.5.5 0 00-.157.483l1.466 6.582a.5.5 0 01-.742.539l-5.807-3.428a.5.5 0 00-.508 0l-5.806 3.428a.5.5 0 01-.743-.54l1.466-6.58a.5.5 0 00-.157-.484l-5.054-4.463a.5.5 0 01.283-.873l6.712-.64a.5.5 0 00.412-.298l2.682-6.186z"
      ></path>
      <path
        fill="url(#paint1_linear_352_313)"
        d="M84.541 6.058a.5.5 0 01.918 0l1.596 3.681a.5.5 0 00.411.299l3.995.38a.5.5 0 01.283.873l-3.008 2.656a.5.5 0 00-.157.483l.873 3.917a.5.5 0 01-.743.539l-3.455-2.04a.5.5 0 00-.508 0l-3.455 2.04a.5.5 0 01-.743-.54l.873-3.916a.5.5 0 00-.157-.483l-3.008-2.656a.5.5 0 01.283-.873l3.995-.38a.5.5 0 00.411-.299l1.596-3.681z"
      ></path>
      <path
        fill="url(#paint2_linear_352_313)"
        d="M44.541 6.058a.5.5 0 01.918 0l1.596 3.681a.5.5 0 00.411.299l3.995.38a.5.5 0 01.283.873l-3.008 2.656a.5.5 0 00-.157.483l.873 3.917a.5.5 0 01-.743.539l-3.455-2.04a.5.5 0 00-.508 0l-3.455 2.04a.5.5 0 01-.743-.54l.873-3.916a.5.5 0 00-.157-.483l-3.008-2.656a.5.5 0 01.283-.873l3.995-.38a.5.5 0 00.411-.299l1.596-3.681z"
      ></path>
      <path
        fill="url(#paint3_linear_352_313)"
        d="M98.854 11.243a.5.5 0 01.43-.243h15.833a.5.5 0 01.429.757l-2.4 4a.5.5 0 01-.429.243H96.883a.5.5 0 01-.429-.757l2.4-4z"
      ></path>
      <path
        fill="url(#paint4_linear_352_313)"
        d="M16.854 15.757a.5.5 0 00.43.243h15.833a.5.5 0 00.429-.757l-2.4-4a.5.5 0 00-.43-.243H14.884a.5.5 0 00-.429.757l2.4 4z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_352_313"
          x1="77"
          x2="48.697"
          y1="24"
          y2="16.711"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BA9525"></stop>
          <stop offset="0.771" stopColor="#F2D58A"></stop>
          <stop offset="1" stopColor="#CFCEB8"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_352_313"
          x1="93"
          x2="74.131"
          y1="21"
          y2="16.141"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BA9525"></stop>
          <stop offset="0.771" stopColor="#F2D58A"></stop>
          <stop offset="1" stopColor="#CFCEB8"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_352_313"
          x1="53"
          x2="34.131"
          y1="21"
          y2="16.141"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BA9525"></stop>
          <stop offset="0.771" stopColor="#F2D58A"></stop>
          <stop offset="1" stopColor="#CFCEB8"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_352_313"
          x1="116"
          x2="103.797"
          y1="16"
          y2="3.43"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BA9525"></stop>
          <stop offset="0.771" stopColor="#F2D58A"></stop>
          <stop offset="1" stopColor="#CFCEB8"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_352_313"
          x1="34"
          x2="21.797"
          y1="11"
          y2="23.57"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BA9525"></stop>
          <stop offset="0.771" stopColor="#F2D58A"></stop>
          <stop offset="1" stopColor="#CFCEB8"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Sage;
