import React from "react";

const ReferralsIcon = () => {
  return (
    <svg width="118px" height="118px" fill="none" viewBox="0 0 118 118">
      <path
        fill="url(#paint0_linear_204_5093)"
        d="M68.252 20.701h-18.5a1.85 1.85 0 00-1.85 1.85v18.5a1.85 1.85 0 001.85 1.85h18.5a1.85 1.85 0 001.85-1.85v-18.5a1.85 1.85 0 00-1.85-1.85z"
        opacity="0.25"
      ></path>
      <path
        stroke="#fff"
        strokeDasharray="1 4.1"
        d="M70.104 17.002h24.05a1.85 1.85 0 011.85 1.85v24.05a1.85 1.85 0 01-1.85 1.85h-24.05a1.85 1.85 0 01-1.85-1.85v-24.05a1.85 1.85 0 011.85-1.85z"
        clipRule="evenodd"
        opacity="0.4"
      ></path>
      <path
        fill="url(#paint1_linear_204_5093)"
        d="M68.252 65.1h-18.5a1.85 1.85 0 00-1.85 1.85v18.5a1.85 1.85 0 001.85 1.85h18.5a1.85 1.85 0 001.85-1.85v-18.5a1.85 1.85 0 00-1.85-1.85z"
        opacity="0.25"
      ></path>
      <path
        fill="#D8D8D8"
        d="M70.102 42.902h-22.2v22.2h22.2v-22.2z"
        opacity="0.2"
      ></path>
      <path
        fill="url(#paint2_linear_204_5093)"
        d="M46.053 42.901h-18.5a1.85 1.85 0 00-1.85 1.85v18.5a1.85 1.85 0 001.85 1.85h18.5a1.85 1.85 0 001.85-1.85v-18.5a1.85 1.85 0 00-1.85-1.85z"
        opacity="0.25"
      ></path>
      <g filter="url(#filter0_d_204_5093)">
        <path
          fill="#000"
          d="M23.852 17.002h24.05a1.85 1.85 0 011.85 1.85v24.05a1.85 1.85 0 01-1.85 1.85h-24.05a1.85 1.85 0 01-1.85-1.85v-24.05a1.85 1.85 0 011.85-1.85z"
        ></path>
      </g>
      <path
        fill="url(#paint3_linear_204_5093)"
        d="M23.852 17.002h24.05a1.85 1.85 0 011.85 1.85v24.05a1.85 1.85 0 01-1.85 1.85h-24.05a1.85 1.85 0 01-1.85-1.85v-24.05a1.85 1.85 0 011.85-1.85z"
      ></path>
      <g filter="url(#filter1_d_204_5093)">
        <path
          fill="#000"
          d="M46.975 40.126h24.05a1.85 1.85 0 011.85 1.85v24.05a1.85 1.85 0 01-1.85 1.85h-24.05a1.85 1.85 0 01-1.85-1.85v-24.05a1.85 1.85 0 011.85-1.85z"
        ></path>
      </g>
      <path
        fill="url(#paint4_linear_204_5093)"
        d="M46.975 40.126h24.05a1.85 1.85 0 011.85 1.85v24.05a1.85 1.85 0 01-1.85 1.85h-24.05a1.85 1.85 0 01-1.85-1.85v-24.05a1.85 1.85 0 011.85-1.85z"
      ></path>
      <g filter="url(#filter2_d_204_5093)">
        <path
          fill="#000"
          d="M70.1 63.25h24.05A1.85 1.85 0 0196 65.1v24.05A1.85 1.85 0 0194.15 91H70.1a1.85 1.85 0 01-1.85-1.85V65.1a1.85 1.85 0 011.85-1.85z"
        ></path>
      </g>
      <path
        fill="url(#paint5_linear_204_5093)"
        d="M70.1 63.25h24.05A1.85 1.85 0 0196 65.1v24.05A1.85 1.85 0 0194.15 91H70.1a1.85 1.85 0 01-1.85-1.85V65.1a1.85 1.85 0 011.85-1.85z"
      ></path>
      <path
        fill="#fff"
        d="M36.112 33.401a.244.244 0 01-.18-.07.253.253 0 01-.06-.17v-5.18l-1.52 1.17a.267.267 0 01-.18.05.281.281 0 01-.16-.11l-.42-.54a.283.283 0 01-.05-.19.244.244 0 01.11-.16l2.24-1.73a.254.254 0 01.12-.06.788.788 0 01.13-.01h.88c.067 0 .123.024.17.07.047.047.07.104.07.17v6.52a.231.231 0 01-.07.17.232.232 0 01-.17.07h-.91zM56.692 56.525a.231.231 0 01-.17-.07.231.231 0 01-.07-.17v-6.51c0-.073.023-.133.07-.18a.231.231 0 01.17-.07h4.4c.073 0 .133.024.18.07.047.047.07.107.07.18v.66a.232.232 0 01-.07.17.244.244 0 01-.18.07h-3.29v1.77h3.07c.073 0 .133.024.18.07.047.04.07.097.07.17v.62a.244.244 0 01-.07.18.267.267 0 01-.18.06h-3.07v1.83h3.37c.073 0 .133.024.18.07.047.047.07.107.07.18v.66a.232.232 0 01-.07.17.244.244 0 01-.18.07h-4.48zM79.73 79.65a.253.253 0 01-.17-.06.244.244 0 01-.07-.18V72.9c0-.073.024-.133.07-.18a.231.231 0 01.17-.07h.91c.074 0 .13.024.17.07.048.047.07.107.07.18v2.58h2.95V72.9c0-.073.024-.133.07-.18a.231.231 0 01.17-.07h.9c.074 0 .134.024.18.07.048.047.07.107.07.18v6.51a.231.231 0 01-.07.17.243.243 0 01-.18.07h-.9a.231.231 0 01-.17-.07.232.232 0 01-.07-.17v-2.68h-2.95v2.68a.231.231 0 01-.07.17.212.212 0 01-.17.07h-.91z"
      ></path>
      <path
        fill="#fff"
        d="M78.443 35.089a.272.272 0 01-.18-.072.244.244 0 01-.072-.18c0-.032.008-.068.024-.108l2.892-7.74a.418.418 0 01.42-.3h1.308c.12 0 .212.032.276.096a.527.527 0 01.144.204l2.892 7.74c.008.04.012.076.012.108a.244.244 0 01-.072.18.23.23 0 01-.168.072h-1.044c-.112 0-.196-.024-.252-.072a.485.485 0 01-.096-.156l-.564-1.464H80.4l-.564 1.464a.423.423 0 01-.108.156c-.048.048-.128.072-.24.072h-1.044zm2.376-3.072h2.724l-1.368-3.708-1.356 3.708z"
        opacity="0.5"
      ></path>
      <defs>
        <filter
          id="filter0_d_204_5093"
          width="71.749"
          height="71.749"
          x="0.002"
          y="0.002"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="5"></feOffset>
          <feGaussianBlur stdDeviation="11"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_204_5093"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_204_5093"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d_204_5093"
          width="71.749"
          height="71.749"
          x="23.125"
          y="23.126"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="5"></feOffset>
          <feGaussianBlur stdDeviation="11"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_204_5093"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_204_5093"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter2_d_204_5093"
          width="71.749"
          height="71.749"
          x="46.25"
          y="46.25"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="5"></feOffset>
          <feGaussianBlur stdDeviation="11"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_204_5093"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_204_5093"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_204_5093"
          x1="47.902"
          x2="70.546"
          y1="20.701"
          y2="43.123"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity="0.1"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0.5"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_204_5093"
          x1="47.902"
          x2="70.546"
          y1="65.101"
          y2="87.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity="0.1"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0.5"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_204_5093"
          x1="25.703"
          x2="48.347"
          y1="42.901"
          y2="65.323"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity="0.1"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0.5"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_204_5093"
          x1="49.751"
          x2="22.002"
          y1="44.751"
          y2="17.002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#556FF2"></stop>
          <stop offset="1" stopColor="#00BFFF"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_204_5093"
          x1="72.874"
          x2="45.125"
          y1="67.875"
          y2="40.126"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5C26FF"></stop>
          <stop offset="1" stopColor="#8C66FF"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_204_5093"
          x1="95.999"
          x2="68.25"
          y1="90.999"
          y2="63.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9F19"></stop>
          <stop offset="1" stopColor="#FC3"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ReferralsIcon;
