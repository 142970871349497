import { useCallback, useEffect, useRef } from "react";

import useAuth from "./useAuth";
import useWallet from "./useWallet";
import { getAlgorandProvider } from "../utils/wallet";

export default function useSignInWithWallet() {
  const { connecting, connect, disconnect, installed } = useWallet();

  const { signedIn, signingIn, signIn, signOut } = useAuth();

  const didAttemptAutoConnectRef = useRef(false);
  useEffect(() => {
    const wallet = getAlgorandProvider();

    if (wallet === null) {
      return;
    }

    if (didAttemptAutoConnectRef.current) {
      return;
    }

    didAttemptAutoConnectRef.current = true;

    connect(true).catch((err) => {
      const userRejectedRequest = err.code === 4001;
      if (userRejectedRequest) {
        signOut();
      }
    });
  }, [installed, signOut, connect]);

  const connectAndSignIn = useCallback(async () => {
    try {
      await connect();
      await signIn();
    } catch (err: any) {
      const userRejectedRequest = err.code === 4001;
      if (userRejectedRequest) {
        await disconnect();
        return;
      }

      throw err;
    }
  }, [connect, signIn, disconnect]);

  const signOutAndDisconnect = useCallback(async () => {
    await signOut();
    await disconnect();
  }, [signOut, disconnect]);

  return {
    signedIn: signedIn,
    signingIn: connecting || signingIn,
    signIn: connectAndSignIn,
    signOut: signOutAndDisconnect,
  };
}
