import invariant from "tiny-invariant";
import { useContext } from "react";

import ProfileContext from "../context/ProfileContext";

export default function useProfile() {
  const context = useContext(ProfileContext);
  invariant(
    typeof context !== "undefined",
    "useProfile must be used within a ProfileProvider"
  );
  return context;
}
