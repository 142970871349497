import { styled } from "../../stitches.config";

const Wrapper = styled("p", {
  fontStyle: "normal",
  textAlign: "center",
  color: "#FFFFFF",
});

const Integer = styled("span", {
  fontWeight: 700,

  variants: {
    size: {
      sm: { fontSize: 40 },
      lg: { fontSize: 60 },
    },
  },
});

const Decimal = styled("span", {
  fontWeight: 500,

  variants: {
    size: {
      sm: { fontSize: 20 },
      lg: { fontSize: 30 },
    },
  },
});

type StatNumberProp = {
  value: number | null;
  size: "sm" | "lg";
};

const StatNumber = ({ value, size }: StatNumberProp) => {
  if (value === null) {
    return (
      <Wrapper>
        <Integer size={size}>-</Integer>
      </Wrapper>
    )
  }

  const integerPart = Math.floor(value);

  const decimalPart = Math.round((value % 1) * 100) / 100;

  return (
    <Wrapper>
      <Integer size={size}>{integerPart.toLocaleString()}</Integer>

      {!!decimalPart && (
        <Decimal size={size}>.{decimalPart.toFixed(2).substring(2)}</Decimal>
      )}
    </Wrapper>
  );
};

export default StatNumber;
