import { globalCss } from "./stitches.config";

export default globalCss({
  "*": { margin: 0, padding: 0, color: "white", boxSizing: "border-box" },
  body: { backgroundColor: "#15172B" },
  p: { fontFamily: "Rubik" },
  h1: { fontFamily: "Rubik" },
  button: { fontFamily: "Rubik" },
  table: { fontFamily: "Rubik" },

  "@font-face": [
    {
      fontFamily: "Rubik",
      src: "url(/fonts/rubik/Rubik-Regular.ttf) format(truetype)",
      fontWeight: 400,
      fontStyle: "normal",
    },
    {
      fontFamily: "Rubik",
      src: "url(/fonts/rubik/Rubik-Medium.ttf) format(truetype)",
      fontWeight: 500,
      fontStyle: "normal",
    },
    {
      fontFamily: "Rubik",
      src: "url(/fonts/rubik/Rubik-Bold.ttf) format(truetype)",
      fontWeight: 700,
      fontStyle: "normal",
    },
  ],
});
