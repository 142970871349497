import useNextReward from "./useNextReward";
import useRewardHistory from "./useRewardHistory";
import useUser from "./useUser";

export default function usePollFetch() {
  const user = useUser();
  const reward = useNextReward();
  const history = useRewardHistory();

  return {
    user,
    reward,
    history,
  };
}
