import { styled } from "../../stitches.config";

const Container = styled("div", {
  width: "100vw",
  height: "100%",
  paddingLeft: 10,
  paddingRight: 10,
  display: 'flex',
  flexDirection: 'column',

  "@lg": {
    width: 1170,
    paddingLeft: 0,
    paddingRight: 0,
  },
});

export default Container;
