import { styled } from "../../../../stitches.config";

import ProgressBar from "../../../../components/ProgressBar";
import Icon from "../../../../components/Icon";
import Tooltip from "../../../../components/Tooltip";
import { useState } from "react";
import useI18n from "../../../../hooks/useI18n";

const Wrapper = styled("div", {
  position: "relative",
  height: 130,
  width: "100%",
  borderRadius: 15,
  padding: "25px 30px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  background:
    "linear-gradient(180.98deg, rgba(255, 255, 255, 0.07) -24.21%, rgba(255, 255, 255, 0.0175) 116.35%)",
  boxShadow:
    "0px 2px 4px rgba(0, 0, 0, 0.12), inset 0px 1px 2px rgba(255, 255, 255, 0.04)",

  "@lg": {
    width: 355,
    marginTop: 0,
  },
});

const Row = styled("div", {
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "space-between",
});

const Text = styled("p", {
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 14,
  display: "flex",
  flexDirection: "column",
});

const Level = styled("span", {
  fontSize: 14,
  color: "rgba(255, 255, 255, 0.4)",
});

const Value = styled("span", {
  fontSize: 24,
  marginTop: 4,
  color: "#FFFFFF",
});

const InfoIcon = styled(Icon, {
  position: "absolute",
  top: 15,
  right: 15,
});

type SwapRewardProps = {
  rewardableSwaps: number;
  swapLimit: number;
};

const SwapReward = ({ rewardableSwaps, swapLimit }: SwapRewardProps) => {
  const { t } = useI18n();
  const [tooltipShown, setTooltipShown] = useState(false);

  const toogleTooltip = () => setTooltipShown(!tooltipShown);

  const hideTooltip = () => setTooltipShown(false);

  const tooltipText = t(
    `You earn rewards on your first $${swapLimit?.toLocaleString()} of swaps. After that, you can still earn rewards from your friend’s swaps.`
  );

  return (
    <Wrapper>
      <Tooltip
        text={tooltipText}
        showTooltip={tooltipShown}
        onHideToolTip={hideTooltip}
      />
      <InfoIcon type="info-circle" onClick={toogleTooltip} />
      <Row>
        <Text>
          <Level>{t("Your Rewarded Swaps")}</Level>
          <Value>{`$${rewardableSwaps}/$${swapLimit}`}</Value>
        </Text>
      </Row>

      <ProgressBar max={swapLimit} value={rewardableSwaps} color="#61C2B2" />
    </Wrapper>
  );
};

export default SwapReward;
