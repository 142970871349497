import globalStyles from "./globalStyles";

import Layout from "./components/Layout";
import Root from "./screens/Root";
import Connect from "./screens/Connect";
import useSignInWithWallet from "./hooks/useSignInWithWallet";

const Screens = () => {
  const { signedIn, signingIn } = useSignInWithWallet();

  if (!signedIn) return <Connect signingIn={signingIn} />;

  return <Root />;
};

const App = () => {
  globalStyles();

  return (
    <Layout>
      <Screens />
    </Layout>
  );
};

export default App;
