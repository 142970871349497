import useI18n from "../../hooks/useI18n";
import { EmptyStats } from "../../screens/Home/components/Stats/Stats";
import { styled } from "../../stitches.config";
import Typography from "../Typography";

const Hero = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  "@lg": {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

const Wrapper = styled("div", {
  height: 130,
  width: "100%",
  marginBottom: 37,
  borderRadius: 15,
  padding: "25px 30px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  background:
    "linear-gradient(180.98deg, rgba(255, 255, 255, 0.07) -24.21%, rgba(255, 255, 255, 0.0175) 116.35%)",
  boxShadow:
    "0px 2px 4px rgba(0, 0, 0, 0.12), inset 0px 1px 2px rgba(255, 255, 255, 0.04)",
  gap: 10,

  "@lg": {
    width: "45%",
    marginTop: 0,
    gap: 10,
    alignItems: "flex-start",
  },
});

const EmptyBar = styled("div", {
  width: "100%",
  height: "8px",
  borderRadius: 4,
  marginTop: "14px",
  overflow: "hidden",
  background: "rgba(255, 255, 255, 0.1)",
});

const Row = styled("div", {
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "space-between",
});

const Text = styled(Typography, {
  color: "#FFFFFF",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 14,
});

const LoadingScreen = () => {
  const { t } = useI18n();

  return (
    <div>
      <Hero>
        <Wrapper>
          <EmptyBar style={{ width: "46%", height: "14px" }} />
          <EmptyBar
            style={{ width: "63%", height: "5px", marginTop: "14px" }}
          />
          <EmptyBar />
        </Wrapper>

        <Wrapper>
          <Text>{t("Current Level")}</Text>
          <Row>
            <EmptyBar style={{ width: "63%", height: "5px" }} />
            <EmptyBar style={{ width: "20%", height: "5px" }} />
          </Row>
          <EmptyBar />
        </Wrapper>
      </Hero>

      <EmptyStats />
    </div>
  );
};

export default LoadingScreen;
