import useSWR from "swr";

import type { Reward } from "../types";

import useFetcher from "./useFetcher";

export default function useRewardHistory() {
  const fetcher = useFetcher();

  const { data, error, isLoading } = useSWR<Reward[]>(
    "/rewards/history",
    fetcher,
    { refreshInterval: 5000 }
  );

  return {
    rewardHistory: data,
    loading: isLoading,
    error,
  };
}
