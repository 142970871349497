import type { ReactNode } from "react";

import { styled } from "../../stitches.config";

import Header from "./Header";
import Container from "./Container";

const Wrapper = styled("main", {
  minHeight: "100vh",
  display: "flex",
  alignItems: 'center',
  flexDirection: "column",
});

const Content = styled(Container, {
  paddingTop: 90,
  paddingBottom: 60,

  "@lg": {
    paddingTop: 110,
    paddingBottom: 125,
  },
});

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <Wrapper>
      <Header />

      <Content>{children}</Content>
    </Wrapper>
  );
};

export default Layout;
