type UsdcIconProps = {
  size?: number
}

const UsdcIcon = ({ size = 30 }: UsdcIconProps) => {
  return (
    <svg width={size} height={size} fill="none" viewBox="0 0 30 30">
      <path
        fill="#000"
        fillOpacity="0.25"
        d="M16.5.866l9.99 5.767a3 3 0 011.5 2.598v11.537a3 3 0 01-1.5 2.598l-9.99 5.767a3 3 0 01-3 0l-9.99-5.767a3 3 0 01-1.5-2.598V9.23a3 3 0 011.5-2.598L13.5.866a3 3 0 013 0z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M13.242 21.566c0 .223-.178.35-.386.282a7.131 7.131 0 010-13.583c.215-.066.386.06.386.284v.556a.425.425 0 01-.253.371c-2.278.839-3.903 3.028-3.903 5.581a5.935 5.935 0 003.903 5.574.426.426 0 01.253.371v.564zm2.375-2.055a.298.298 0 01-.297.297h-.594a.298.298 0 01-.297-.297v-.936c-1.299-.178-1.93-.898-2.1-1.893a.274.274 0 01.275-.318h.675a.3.3 0 01.29.237c.126.586.467 1.04 1.507 1.04.763 0 1.312-.431 1.312-1.07 0-.638-.318-.883-1.446-1.068-1.663-.223-2.45-.728-2.45-2.027 0-1.002.765-1.789 1.937-1.951v-.92c0-.164.134-.298.297-.298h.594c.163 0 .297.134.297.297v.942c.957.171 1.566.713 1.767 1.619a.272.272 0 01-.275.326h-.624a.294.294 0 01-.282-.215c-.17-.572-.579-.824-1.291-.824-.787 0-1.195.378-1.195.913 0 .564.23.846 1.44 1.017 1.633.223 2.479.69 2.479 2.078 0 1.054-.787 1.908-2.012 2.1v.95h-.007zm1.188 2.055c0 .222.17.35.385.282a7.124 7.124 0 004.958-6.79A7.098 7.098 0 0017.19 8.28c-.208-.067-.385.06-.385.282v.557a.425.425 0 00.252.37 5.937 5.937 0 013.904 5.575c0 2.553-1.633 4.743-3.904 5.574a.387.387 0 00-.252.37v.557z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default UsdcIcon;
