import { useEffect, useState } from "react";

import { getAlgorandProvider } from "../utils/wallet";
import { scopePollingDetectionStrategy } from "../utils/polling";

export default function useDetectInjectedWallet() {
  const [detected, setDetected] = useState(false);

  // Detect the injected wallet.
  useEffect(() => {
    const detect = () => {
      const wallet = getAlgorandProvider();
      const detected = wallet !== null;

      setDetected(detected);

      return detected;
    };

    scopePollingDetectionStrategy(detect)
  }, []);

  return detected;
}
