import React, { useMemo } from "react";
import type { ReactNode } from "react";

import type { Fetcher } from "../utils/api";
import useAuth from "../hooks/useAuth";
import { createFetcher } from "../utils/api";

type FetcherContextValue = Fetcher;

const FetcherContext = React.createContext<FetcherContextValue | undefined>(
  undefined
);

export function FetcherProvider({ children }: { children: ReactNode }) {
  const { token } = useAuth();

  const fetcher = useMemo(() => createFetcher(token), [token]);

  return (
    <FetcherContext.Provider value={fetcher}>
      {children}
    </FetcherContext.Provider>
  );
}

export default FetcherContext;
