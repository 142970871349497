import type { CSSProperties } from "react";

import Enthusiast from "./Enthusiast";
import Trailblazer from "./Trailblazer";
import Champion from "./Champion";
import Sage from "./Sage";
import Legend from "./Legend";
import { styled } from "../../stitches.config";
import { ReferrerLevel } from "../../types";

type LevelInsigniaProps = {
  level: ReferrerLevel;
  style?: CSSProperties
};

const INSIGNIAS = {
  enthusiast: Enthusiast,
  trailblazer: Trailblazer,
  champion: Champion,
  sage: Sage,
  legend: Legend
}

const Wrapper = styled('div', {
  width: 130,
  height: 30,
  display: 'flex',
  justifyContent: 'center',
})

const LevelInsignia = ({ level, style }: LevelInsigniaProps) => {
  const Component = INSIGNIAS[level]

  if (!Component) return null;

  return (
    <Wrapper style={style}>
      <Component />
    </Wrapper>
  );
};

export default LevelInsignia;
