import LoadingScreen from "../../components/Spinner/LoadingScreen";
import usePollFetch from "../../hooks/usePollFetch";
import Home from "../Home";

const Root = () => {
  const {
    user: { loading },
  } = usePollFetch();

  if (loading) return <LoadingScreen />;

  return <Home />;
};

export default Root;
