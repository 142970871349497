import { ReactNode, useEffect, useRef, useState } from "react";
import { styled } from "../../stitches.config";

const TooltipContainer = styled("div", {
  maxWidth: "100%",
  position: "absolute",
  borderRadius: 15,
  width: 276,
  bottom: 128,
  right: 6,
  zIndex: 1000,
});

const TooltipTextContainer = styled("div", {
  fontFamily: "Rubik",
  fontSize: 12,
  borderRadius: 4,
  padding: 15,
  background: "#000000",
  color: "#FFF",
  textAlign: "center",
  justifyContent: "center",
});

const TooltipArrow = styled("div", {
  position: "absolute",
  backgroundColor: "#000000",
  bottom: -6,
  right: 15,
  width: 13,
  height: 13,
  transform: "rotate(45deg)",
});

type TooltipProps = {
  text: ReactNode | string;
  showTooltip: boolean;
  onHideToolTip?: () => void;
};

const Tooltip = ({ text, showTooltip, onHideToolTip }: TooltipProps) => {
  const [tooltipShown, setTooltipShown] = useState(false);
  const tooltipTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (tooltipTimeout.current) {
      clearTimeout(tooltipTimeout.current);
      setTooltipShown(false);
    }

    if (showTooltip) {
      setTooltipShown(true);

      tooltipTimeout.current = setTimeout(() => {
        setTooltipShown(false);
        onHideToolTip && onHideToolTip()
      }, 2500);
    }

    return () => {
      if (tooltipTimeout.current) {
        clearTimeout(tooltipTimeout.current);
      }
    };
  }, [showTooltip, onHideToolTip]);
  
  if (!tooltipShown) return null;

  return (
    <TooltipContainer>
      <TooltipArrow />
      <TooltipTextContainer>{text}</TooltipTextContainer>
    </TooltipContainer>
  );
};

export default Tooltip;
