import invariant from "tiny-invariant";
import { useContext } from "react";

import WalletContext from "../context/WalletContext";

export default function useWallet() {
  const context = useContext(WalletContext);
  invariant(
    typeof context !== "undefined",
    "useWallet must be used within a WalletProvider"
  );
  return context;
}
