import { isDev } from "../constants";

const baseURL = isDev ? "https://referrals-s.a.exodus.io/dashboard" : "https://referrals.a.exodus.io/dashboard";

type JSONResponse =
  | {
      success: true;
      data: any;
      error: undefined;
    }
  | {
      success: false;
      data: undefined;
      error: { detail: string };
    };

export type Fetcher = (path: string) => Promise<any>;

export function createFetcher(token: string | null): Fetcher {
  return async (path: string) => {
    const response = await fetch(`${baseURL}${path}`, {
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
      },
    });

    const { success, data, error }: JSONResponse = await response.json();

    if (!success) {
      // See: https://swr.vercel.app/docs/error-handling#status-code-and-error-object.
      throw new Error(error.detail);
    }

    return data;
  };
}
