import useSWR from "swr";

import useFetcher from "./useFetcher";
import { KycStatus, ReferrerLevel } from "../types";
import { useEffect, useState } from "react";

export type User = {
  kycStatus: KycStatus;
  countryCode: string;
  refereeCount: number;
  referralCode: string;
  referrerLevel: ReferrerLevel;
  isReferee: boolean;
  rewardableSwaps: number;
  isLegacyReferee: boolean;
  swapLimit: number;
};

export default function useUser() {
  const fetcher = useFetcher();

  // useSWR sets isLoading to true at every revalidation when endpoint fails
  // I didn't find a way to use it's options to prevent it, so using state hack instead
  const [loading, setLoading] = useState(true);

  const { data, error, isLoading } = useSWR<User>("/whoami", fetcher, {
    refreshInterval: 5000,
  });

  useEffect(() => {
    setLoading(!loading ? false : isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return {
    user: data,
    loading,
    error,
  };
}
