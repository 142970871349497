type VerificationWarningProps = {
  size?: number;
};

const VerificationWarning = ({ size = 24 }: VerificationWarningProps) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
  >
    <path
      fill="url(#a)"
      fillRule="evenodd"
      d="M9.939 2.465c.93-1.564 3.194-1.564 4.124 0l9.588 16.107c.952 1.6-.2 3.628-2.062 3.628H2.413c-1.862 0-3.015-2.028-2.062-3.628L9.939 2.465Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="m13.381 6.729-.552 8.29H11.17l-.552-8.29h2.762Zm-.563 11.838c-.192.209-.464.313-.817.313-.352 0-.624-.104-.816-.313a1.107 1.107 0 0 1-.289-.779c0-.321.1-.588.297-.8.197-.211.467-.317.808-.317.342 0 .611.106.809.317.197.212.296.479.296.8 0 .31-.096.57-.288.78Z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="a"
        x1={39.62}
        x2={16.768}
        y1={10.6}
        y2={-16.605}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8000" />
        <stop offset={1} stopColor="#FB3" />
      </linearGradient>
    </defs>
  </svg>
  );
};

export default VerificationWarning;
