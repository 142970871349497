import invariant from "tiny-invariant";
import { useContext } from "react";

import FetcherContext from "../context/FetcherContext";

export default function useFetcher() {
  const context = useContext(FetcherContext);
  invariant(
    typeof context !== "undefined",
    "useFetcher must be used within an FetcherProvider"
  );
  return context;
}
