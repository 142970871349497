import { useMemo } from "react";

import { keyframes } from "../../stitches.config";

const FROM_COLOR = "#720DFF";

const TO_COLOR = "#A16BB2";

const spin = keyframes({
  "100%": { transform: "rotate(360deg)" },
});

type LoadingSpinnerProps = {
  radius?: number;
  strokeWidth?: number;
  fromColor?: string;
  toColor?: string;
};

const LoadingSpinner = ({
  radius = 25,
  strokeWidth = 5,
  fromColor = FROM_COLOR,
  toColor = TO_COLOR,
}: LoadingSpinnerProps) => {
  const circleLength = 2 * Math.PI * radius;

  const dash = useMemo(
    () =>
      keyframes({
        "0%": {
          // ...
          strokeDasharray: `1, ${circleLength}`,
          strokeDashoffset: 0,
        },
        "50%": {
          strokeDasharray: `${circleLength * 0.6}, ${circleLength}`,
          strokeDashoffset: -0.23 * circleLength,
        },
        "100%": {
          strokeDasharray: `${circleLength * 0.6}, ${circleLength}`,
          strokeDashoffset: -0.826 * circleLength,
        },
      }),
    [circleLength]
  );

  return (
    <svg
      viewBox={`0 0 ${radius * 2} ${radius * 2}`}
      width={radius * 2}
      height={radius * 2}
      style={{ animation: `${spin} 2s linear 0.3s infinite` }}
    >
      <linearGradient id="spinner">
        <stop offset="0%" stopColor={fromColor} />
        <stop offset="100%" stopColor={toColor} />
      </linearGradient>

      <circle
        cx={radius}
        cy={radius}
        r={radius - strokeWidth}
        fill="none"
        strokeWidth={strokeWidth}
        stroke={`url(#spinner)`}
        strokeLinecap="round"
        style={{ animation: `${dash} 1.5s linear infinite` }}
      />
    </svg>
  );
};

export default LoadingSpinner;
