const InfoCircle = ({ ...props }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="11"
        cy="11"
        r="11"
        fill="url(#paint0_linear_1226_2581)"
        fill-opacity="0.1"
      />
      <g opacity="0.6">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.2126 9.67391C12.2095 9.68962 12.2053 9.70429 12.2053 9.74724V13.9482L12.8548 14.0634C13.079 14.0634 13.2603 14.2751 13.2603 14.5349C13.2603 14.7936 13.079 15.0042 12.8548 15.0042H9.45321C9.23007 15.0042 9.04883 14.7936 9.04883 14.5338C9.04883 14.2751 9.23007 14.0634 9.45321 14.0634L10.1048 13.9482V10.797L9.45321 10.6754C9.23007 10.6754 9.04883 10.4649 9.04883 10.2051C9.04883 9.94524 9.23007 9.73362 9.45321 9.73362H12.0932C12.1749 9.73362 12.2011 9.70115 12.2126 9.67391ZM11.1567 6.5918L11.1777 6.59599H12.2075V7.58389C12.2075 7.60694 12.22 7.6258 12.22 7.6478C12.22 7.67084 12.2085 7.6897 12.2075 7.7117V8.69646H11.1913C11.1787 8.69751 11.1682 8.7038 11.1567 8.7038L11.1221 8.69646H10.1059V7.7117C10.1049 7.68865 10.0934 7.67084 10.0934 7.6478C10.0934 7.6258 10.1039 7.60589 10.107 7.58494V6.59599H11.1358C11.1431 6.59599 11.1494 6.5918 11.1567 6.5918Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1226_2581"
          x1="21.98"
          y1="21.9599"
          x2="21.98"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0.5" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default InfoCircle;
