import React from "react";

const DefaultAvatar = () => {
  return (
    <svg
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 90 90"
    >
      <g clipPath="url(#clip0_352_335)">
        <g filter="url(#filter0_i_352_335)">
          <rect
            width="90"
            height="90"
            fill="url(#paint0_linear_352_335)"
            fillOpacity="0.07"
            rx="20"
          ></rect>
        </g>
        <path
          fill="url(#paint1_linear_352_335)"
          fillRule="evenodd"
          d="M60.8 40.443V38.09c-.006-5.325-3.707-9.769-8.54-10.256l-2.523-5.518c-.358-.53-1.359-.338-2.716.172l-9.933 3.891c-4.918 2.219-8.22 7.361-8.468 13.188v.876c-.269-.21-.551-.322-.835-.297-1.207.105-1.986 2.489-1.74 5.322.242 2.786 1.389 4.959 2.575 4.928v1.338C28.62 58.172 35.293 68 44.71 68 54.125 68 60.8 58.17 60.8 51.734v-1.338c1.186.03 2.33-2.142 2.573-4.928.247-2.834-.533-5.217-1.74-5.323-.282-.025-.566.089-.833.298z"
          clipRule="evenodd"
          opacity="0.25"
        ></path>
        <path
          fill="url(#paint2_linear_352_335)"
          fillRule="evenodd"
          d="M37.353 57.306s.632 5.178 7.356 5.178 7.355-5.178 7.355-5.178"
          clipRule="evenodd"
          opacity="0.25"
        ></path>
        <path
          fill="url(#paint3_linear_352_335)"
          fillRule="evenodd"
          d="M58.416 41.96c2.383 0 2.383-1.885 2.383-1.885V38.09c-.005-5.325-3.705-9.769-8.538-10.256l-2.523-5.518c-.36-.53-1.36-.338-2.717.172l-9.933 3.891c-4.917 2.219-8.22 7.36-8.469 13.188v5.508s3.414-4.903 9.386-5.149c5.972-.245 7.3 1.569 12.67 2.01 2.133.176 5.489.025 7.742.025z"
          clipRule="evenodd"
          opacity="0.4"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_i_352_335"
          width="90"
          height="91"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.04 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_352_335"></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_352_335"
          x1="45"
          x2="42.794"
          y1="-22.931"
          y2="105.698"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0.25"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_352_335"
          x1="32.33"
          x2="65.293"
          y1="22"
          y2="62.127"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity="0.1"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0.5"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_352_335"
          x1="37.353"
          x2="50.934"
          y1="59.574"
          y2="64.307"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity="0.1"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0.5"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_352_335"
          x1="61.442"
          x2="34.696"
          y1="39.589"
          y2="20.598"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity="0.1"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0.5"></stop>
        </linearGradient>
        <clipPath id="clip0_352_335">
          <rect width="90" height="90" fill="#fff" rx="20"></rect>
        </clipPath>
      </defs>
    </svg>
  );
}

export default DefaultAvatar;
