// @ts-ignore
import blockies from "@exodus/ethereum-blockies";

const colors = [
  { color: "#FFFFFF", bgcolor: "#7B39FF", spotcolor: "#39BDFF" },
  { color: "#FFFFFF", bgcolor: "#7B39FF", spotcolor: "#FFB726" },
  { color: "#FFFFFF", bgcolor: "#39BDFF", spotcolor: "#FFB726" },
  { color: "#FFFFFF", bgcolor: "#FFB726", spotcolor: "#39BDFF" },
  { color: "#7B39FF", bgcolor: "#FFFFFF", spotcolor: "#39BDFF" },
  { color: "#7B39FF", bgcolor: "#FFFFFF", spotcolor: "#FFB726" },
];

function getBlockieColors(seed: string) {
  let sum = 0;

  for (let i = 0; i < seed.length; i++) {
    sum += seed.charCodeAt(i);
  }

  return colors[sum % colors.length];
}

export function getBlockie(name: string) {
  const colors = getBlockieColors(name);
  return blockies.create({ seed: name, size: 10, scale: 20, ...colors });
}
