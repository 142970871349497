import InfoCircle from "./InfoCircle";
import UsdcIcon from "./Usdc";
import VerificationPending from "./verificationPending";
import VerificationWarning from "./verificationWarning";

const ICONS = {
  usdc: UsdcIcon,
  "verification-pending": VerificationPending,
  "verification-warning": VerificationWarning,
  'info-circle': InfoCircle,
};
const IconsMap = new Map(Object.entries(ICONS));

type IconProps = {
  type: keyof typeof ICONS;
  size?: number;
  onClick?: () => void;
};

const Icon = ({ type, size, ...props }: IconProps) => {
  const Component = IconsMap.get(type);

  if (!Component) return null;

  return <Component size={size} {...props} />;
};

export default Icon;
