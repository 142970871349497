import { styled } from "../../../../stitches.config";

import { ReferrerLevel } from "../../../../types";
import ProgressBar from "../../../../components/ProgressBar";
import useI18n from "../../../../hooks/useI18n";

const FIRE_LEVELS = ['sage', 'legend']

const PROGRESS_BAR_COLORS = {
  enthusiast: "#61C2B2",
  trailblazer: "#9CA1A8",
  champion: "#CC8F14",
  sage: "#556FF2",
  legend: "#5C26FF",
};

const Wrapper = styled("div", {
  height: 130,
  width: "100%",
  borderRadius: 15,
  padding: "25px 30px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  background:
    "linear-gradient(180.98deg, rgba(255, 255, 255, 0.07) -24.21%, rgba(255, 255, 255, 0.0175) 116.35%)",
  boxShadow:
    "0px 2px 4px rgba(0, 0, 0, 0.12), inset 0px 1px 2px rgba(255, 255, 255, 0.04)",

  "@lg": {
    width: 355,
    marginTop: 0,
  },
});

const Row = styled('div', {
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
})

const Text = styled("p", {
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 14,
  display: "flex",
  flexDirection: "column",
});

const Level = styled("span", {
  fontSize: 14,
  color: "rgba(255, 255, 255, 0.4)",
});

const Value = styled("span", {
  fontSize: 24,
  marginTop: 4,
  color: "#FFFFFF",
});

const capitalize = (word: string) => {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
};

type LevelProgressProps = {
  refereeCount: number;
  referrerLevel: ReferrerLevel;
};

const LevelProgress = ({ refereeCount, referrerLevel }: LevelProgressProps) => {
  const showMax = refereeCount < 10

  const { t } = useI18n()

  return (
    <Wrapper>
      <Row>
        <Text>
          <Level>{t("Current Level")}</Level>
          <Value>{capitalize(referrerLevel)}</Value>
        </Text>

        <Value as="p">{FIRE_LEVELS.includes(referrerLevel) && '🔥'} {refereeCount}{showMax ? '/10' : ''}</Value>
      </Row>

      <ProgressBar
        max={10}
        value={refereeCount}
        color={PROGRESS_BAR_COLORS[referrerLevel]}
      />
    </Wrapper>
  );
};

export default LevelProgress;
