import React, { useCallback, useEffect, useMemo, useState } from "react";
import type { ReactNode } from "react";
import { fromByteArray } from "base64-js";

import useLocalStorage from "../hooks/useLocalStorage";
import useWallet from "../hooks/useWallet";
import {
  getAlgorandProvider,
  getExodusInternalProvider,
} from "../utils/wallet";
import useQueryParam from "../hooks/useQueryParam";
import { isDev } from "../constants";

type AuthContextValue = {
  signedIn: boolean;
  signingIn: boolean;
  token: string | null;
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
};

const envKey = "authEnv";
const tokenKey = "authToken";

const AuthContext = React.createContext<AuthContextValue | undefined>(
  undefined
);

export default AuthContext;

export function AuthProvider({ children }: { children: ReactNode }) {
  const queryToken = useQueryParam(tokenKey);
  const env = isDev ? "development" : "production";

  const [persistedEnv, setEnv] = useLocalStorage<string | null>(envKey, null);
  const [persistedToken, setToken] = useLocalStorage<string | null>(
    tokenKey,
    null
  );

  const token = queryToken || (env === persistedEnv ? persistedToken : null);
  const signedIn = !!token;
  const [signingIn, setSigningIn] = useState(false);

  const { signMessage } = useWallet();
  const signIn = useCallback(async () => {
    if (signedIn) {
      return;
    }

    setSigningIn(true);

    try {
      const message = buildMessage();
      const encodedMessage = new TextEncoder().encode(message);
      const { address, signature } = await signMessage(encodedMessage);
      const token = buildToken(address, signature);
      setEnv(env);
      setToken(token);
    } finally {
      setSigningIn(false);
    }
  }, [signedIn, signMessage, setEnv, env, setToken]);

  const signOut = useCallback(async () => {
    if (!signedIn) {
      return;
    }

    setEnv(null);
    setToken(null);
  }, [signedIn, setEnv, setToken]);

  useEffect(() => {
    const wallet = getAlgorandProvider();

    if (!wallet || !signedIn) return;

    const handleDisconnect = () => {
      signOut();
    };

    wallet.on("disconnect", handleDisconnect);

    // TODO: .off isn't in proxy and not sure why conditional check isn't working
    // return () => {
    //   wallet?.off("disconnect", handleDisconnect);
    // };
  }, [signedIn, signOut]);

  useEffect(() => {
    const signInWithMobileAuth = async () => {
      const internalProvider = getExodusInternalProvider();
      if (internalProvider?.getReferralsAuthToken) {
        const authToken = await internalProvider.getReferralsAuthToken();
        setEnv(env);
        setToken(authToken);
      }
    };

    signInWithMobileAuth();
  }, [setToken, setEnv, env]);

  const value = useMemo(
    () => ({
      signedIn,
      signingIn,
      token,
      signIn,
      signOut,
    }),
    [signedIn, signingIn, token, signIn, signOut]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

// TODO: Extract to SIWA (Sign In with Algorand) library.

function buildMessage(): string {
  const { origin } = window.location;
  const { address } = getAlgorandProvider() || {}; // FIXME: Get from context.
  return `${origin} wants you to sign in with your Algorand account: ${address}.`;
}

function buildToken(address: string, signature: Uint8Array): string {
  return `${address}.${fromByteArray(signature)}`;
}
