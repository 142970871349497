type AvatarProps = {
  src: string;
};

const ImageAvatar = ({ src }: AvatarProps) => {
  return (
    <img
      alt="avatar"
      src={src}
      style={{ width: '100%', height: '100%', borderRadius: 20 }}
    />
  );
}

export default ImageAvatar;
