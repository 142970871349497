import React, { useEffect, useMemo, useState } from "react";
import type { ReactNode } from "react";

import type { Profile } from "../utils/profile";
import { getProfileProvider } from "../utils/profile";
import { scopePollingDetectionStrategy } from "../utils/polling";

type ProfileContextValue = {
  profile: Profile | null;
};

const ProfileContext = React.createContext<ProfileContextValue | undefined>(
  undefined
);

export function ProfileProvider({ children }: { children: ReactNode }) {
  const [profile, setProfile] = useState<Profile | null>(null);

  useEffect(() => {
    const detect = () => {
      const profileProvider = getProfileProvider();

      if (profileProvider === null) return false;

      setProfile({
        name: profileProvider.name,
        avatar: profileProvider.avatar,
      });

      profileProvider.on("changed", (profile: Profile) => {
        setProfile(profile);
      });

      return true;
    };

    scopePollingDetectionStrategy(detect);
  }, []);

  const value = useMemo(
    () => ({
      profile,
    }),
    [profile]
  );

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
}

export default ProfileContext;
