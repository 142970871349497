import type { CSSProperties } from "react";

import { styled } from "../../stitches.config";
import LoadingSpinner from "../LoadingSpinner";

const Wrapper = styled("div", {
  width: "100%",
  borderRadius: 10,
  overflow: "hidden",
  backgroundColor: "#212336",
});

const TableElement = styled("table", {
  width: "100%",
  borderCollapse: "collapse",

  th: {
    fontSize: 12,
    fontWeight: 500,
    color: "#FFFFFF",
    backgroundColor: "#720DFF",
  },
});

const Row = styled("tr", {
  height: 60,

  "&:nth-child(odd)": {
    backgroundColor: "rgba(21, 23, 43, 0.5)",
  },
});

const Cell = styled("td", {
  fontSize: 13,
  paddingTop: 20,
  paddingBottom: 20,
  textAlign: "left",
  fontStyle: "normal",
  color: "rgba(255, 255, 255, 0.6)",

  "&:first-child": {
    paddingLeft: 20,
  },

  "&:last-child": {
    paddingRight: 20,
  },

  variants: {
    align: {
      left: { textAlign: "left" },
      center: { textAlign: "center" },
      right: { textAlign: "right" },
    },
    size: {
      lg: {
        paddingTop: 40,
        paddingBottom: 40,
      },
    },
  },

  "@lg": {
    "&:first-child": {
      paddingLeft: 50,
    },

    "&:last-child": {
      paddingRight: 50,
    },
  },
});

type Column = {
  title: string;
  key: string;
  align?: "left" | "center" | "right";
};

type Data = { [key: string]: string };

type TableProps = {
  columns: Column[];
  data: Data[];
  loading?: boolean;
  style?: CSSProperties;
};

const Table = ({ columns, data, loading = false, style }: TableProps) => {
  return (
    <Wrapper style={style}>
      <TableElement>
        <thead>
          <Row>
            {columns.map((column) => (
              <Cell key={column.key} as="th" align={column.align}>
                {column.title}
              </Cell>
            ))}
          </Row>
        </thead>

        <tbody>
          {loading && (
            <Row>
              <Cell colSpan={columns.length} size="lg" align="center">
                <LoadingSpinner />
              </Cell>
            </Row>
          )}

          {!loading &&
            data.map((row) => (
              <Row key={row.key}>
                {columns.map((column) => (
                  <Cell key={column.key} align={column.align}>
                    {row[column.key]}
                  </Cell>
                ))}
              </Row>
            ))}
        </tbody>
      </TableElement>
    </Wrapper>
  );
};

export default Table;
