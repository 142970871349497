import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import type { ReactNode } from "react";

import type { AlgorandEvents, AlgorandProvider } from "../utils/wallet";
import useDetectInjectedWallet from "../hooks/useDetectInjectedWallet";
import { getAlgorandProvider } from "../utils/wallet";

type WalletContextValue = {
  installed: boolean;
  connected: boolean;
  connecting: boolean;
  connect(silent?: boolean): Promise<void>;
  disconnect(): Promise<void>;
  address: AlgorandProvider["address"];
  signMessage: AlgorandProvider["signMessage"];
};

const WalletContext = React.createContext<WalletContextValue | undefined>(
  undefined
);

export function WalletProvider({ children }: { children: ReactNode }) {
  const installed = useDetectInjectedWallet();

  const [address, setAddress] = useState<AlgorandProvider["address"]>(null);

  const connected = address !== null;
  const [connecting, setConnecting] = useState(false);

  // Set up disconnect listener when the wallet is connected.
  useEffect(() => {
    const wallet = getAlgorandProvider();

    if (wallet === null) {
      return;
    }

    if (!connected) {
      return;
    }

    const handleDisconnect: AlgorandEvents["disconnect"] = () => {
      setAddress(null);
    };

    wallet.on("disconnect", handleDisconnect);

    // TODO: .off isn't in proxy and not sure why conditional check isn't working
    // return () => {
    //   wallet?.off("disconnect", handleDisconnect);
    // };
  }, [connected]);

  const connect = useCallback(
    async (silent: boolean) => {
      const wallet = getAlgorandProvider();

      if (wallet === null) {
        throw new Error("Wallet not installed");
      }

      if (connected || connecting) {
        return;
      }

      setConnecting(true);

      try {
        const { address } = await wallet.connect({ onlyIfTrusted: silent });
        setAddress(address);
      } finally {
        setConnecting(false);
      }
    },
    [connected, connecting]
  );

  const disconnect = useCallback(async () => {
    const wallet = getAlgorandProvider();

    if (wallet === null) {
      throw new Error("Wallet not installed");
    }

    if (!connected) {
      return;
    }

    await wallet.disconnect();

    setAddress(null);
  }, [connected]);

  // Sign an arbitrary message if the wallet supports it.
  const signMessage: AlgorandProvider["signMessage"] = useCallback(
    (encodedMessage) => {
      const wallet = getAlgorandProvider();

      if (wallet === null) {
        throw new Error("Wallet not installed");
      }

      if (!("signMessage" in wallet)) {
        throw new Error("Wallet does not support `signMessage`");
      }

      return wallet.signMessage(encodedMessage);
    },
    []
  );

  // Request to connect once when the wallet is detected.

  const value = useMemo(
    () => ({
      installed,
      connected,
      connecting,
      connect: (silent = false) => connect(silent),
      disconnect,
      address,
      signMessage,
    }),
    [
      installed,
      connected,
      connecting,
      connect,
      disconnect,
      address,
      signMessage,
    ]
  );

  return (
    <WalletContext.Provider value={value}>{children}</WalletContext.Provider>
  );
}

export default WalletContext;
