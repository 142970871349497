import { styled } from "../../stitches.config";

import ExodusLogo from "../ExodusLogo";
import Container from "./Container";

const Wrapper = styled("header", {
  top: 0,
  left: 0,
  height: 60,
  position: "fixed",
  width: "100vw",
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#0C0E1D",
  zIndex: 1000,
});

const Content = styled(Container, {
  justifyContent: "center",
  alignItems: "center",

  "@lg": {
    alignItems: "flex-start",
  },
});

const Header = () => {
  return (
    <Wrapper>
      <Content>
        <ExodusLogo />
      </Content>
    </Wrapper>
  );
};

export default Header;
