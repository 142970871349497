import type { CSSProperties } from "react";

type LegendProps = {
  style?: CSSProperties;
};

const Legend = ({ style }: LegendProps) => {
  return (
    <svg width="130" height="31" fill="none" viewBox="0 0 130 31" style={style}>
      <path
        fill="url(#paint0_linear_352_415)"
        d="M64.541 1.058a.5.5 0 01.918 0l2.682 6.186a.5.5 0 00.411.299l6.713.64a.5.5 0 01.283.872l-5.054 4.463a.5.5 0 00-.157.483l1.466 6.582a.5.5 0 01-.742.539l-5.807-3.428a.5.5 0 00-.508 0l-5.806 3.428a.5.5 0 01-.743-.54l1.466-6.58a.5.5 0 00-.157-.484l-5.054-4.463a.5.5 0 01.283-.873l6.712-.64a.5.5 0 00.412-.298l2.682-6.186z"
      ></path>
      <path
        fill="url(#paint1_linear_352_415)"
        d="M84.541 6.058a.5.5 0 01.918 0l1.596 3.681a.5.5 0 00.411.299l3.995.38a.5.5 0 01.283.873l-3.008 2.656a.5.5 0 00-.157.483l.873 3.917a.5.5 0 01-.743.539l-3.455-2.04a.5.5 0 00-.508 0l-3.455 2.04a.5.5 0 01-.743-.54l.873-3.916a.5.5 0 00-.157-.483l-3.008-2.656a.5.5 0 01.283-.873l3.995-.38a.5.5 0 00.411-.299l1.596-3.681z"
      ></path>
      <path
        fill="url(#paint2_linear_352_415)"
        d="M44.541 6.058a.5.5 0 01.918 0l1.596 3.681a.5.5 0 00.411.299l3.995.38a.5.5 0 01.283.873l-3.008 2.656a.5.5 0 00-.157.483l.873 3.917a.5.5 0 01-.743.539l-3.455-2.04a.5.5 0 00-.508 0l-3.455 2.04a.5.5 0 01-.743-.54l.873-3.916a.5.5 0 00-.157-.483l-3.008-2.656a.5.5 0 01.283-.873l3.995-.38a.5.5 0 00.411-.299l1.596-3.681z"
      ></path>
      <path
        fill="url(#paint3_linear_352_415)"
        d="M100.854 7.243a.5.5 0 01.429-.243h20.834a.5.5 0 01.429.757l-2.4 4a.5.5 0 01-.429.243H98.883a.5.5 0 01-.429-.757l2.4-4z"
      ></path>
      <path
        fill="url(#paint4_linear_352_415)"
        d="M9.854 11.757a.5.5 0 00.43.243h20.833a.5.5 0 00.429-.757l-2.4-4a.5.5 0 00-.43-.243H7.884a.5.5 0 00-.429.757l2.4 4z"
      ></path>
      <path
        fill="url(#paint5_linear_352_415)"
        d="M96.854 15.243a.5.5 0 01.43-.243h15.833a.5.5 0 01.429.757l-2.4 4a.5.5 0 01-.429.243H94.883a.5.5 0 01-.429-.757l2.4-4z"
      ></path>
      <path
        fill="url(#paint6_linear_352_415)"
        d="M85.854 23.243a.5.5 0 01.43-.243h15.833a.5.5 0 01.429.757l-2.4 4a.501.501 0 01-.43.243H83.884a.5.5 0 01-.429-.757l2.4-4z"
      ></path>
      <path
        fill="url(#paint7_linear_352_415)"
        d="M18.854 19.757a.5.5 0 00.43.243h15.833a.5.5 0 00.429-.757l-2.4-4a.5.5 0 00-.43-.243H16.884a.5.5 0 00-.429.757l2.4 4z"
      ></path>
      <path
        fill="url(#paint8_linear_352_415)"
        d="M29.854 27.757a.5.5 0 00.43.243h15.833a.5.5 0 00.429-.757l-2.4-4a.5.5 0 00-.43-.243H27.884a.5.5 0 00-.429.757l2.4 4z"
      ></path>
      <path
        fill="url(#paint9_linear_352_415)"
        d="M72.188 28.453a.64.64 0 010-.906l1.359-1.36a.64.64 0 01.906 0l1.36 1.36a.64.64 0 010 .906l-1.36 1.36a.64.64 0 01-.906 0l-1.36-1.36z"
      ></path>
      <path
        fill="url(#paint10_linear_352_415)"
        d="M54.188 28.453a.64.64 0 010-.906l1.359-1.36a.64.64 0 01.906 0l1.36 1.36a.64.64 0 010 .906l-1.36 1.36a.64.64 0 01-.906 0l-1.36-1.36z"
      ></path>
      <path
        fill="url(#paint11_linear_352_415)"
        d="M62.282 28.68a.961.961 0 010-1.36l2.038-2.038a.961.961 0 011.36 0l2.038 2.038a.961.961 0 010 1.36l-2.038 2.038a.961.961 0 01-1.36 0l-2.038-2.038z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_352_415"
          x1="77"
          x2="48.697"
          y1="24"
          y2="16.711"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BA9525"></stop>
          <stop offset="0.771" stopColor="#F2D58A"></stop>
          <stop offset="1" stopColor="#CFCEB8"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_352_415"
          x1="93"
          x2="74.131"
          y1="21"
          y2="16.141"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BA9525"></stop>
          <stop offset="0.771" stopColor="#F2D58A"></stop>
          <stop offset="1" stopColor="#CFCEB8"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_352_415"
          x1="53"
          x2="34.131"
          y1="21"
          y2="16.141"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BA9525"></stop>
          <stop offset="0.771" stopColor="#F2D58A"></stop>
          <stop offset="1" stopColor="#CFCEB8"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_352_415"
          x1="123"
          x2="111.172"
          y1="12"
          y2="-3.23"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BA9525"></stop>
          <stop offset="0.771" stopColor="#F2D58A"></stop>
          <stop offset="1" stopColor="#CFCEB8"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_352_415"
          x1="32"
          x2="20.172"
          y1="7"
          y2="22.23"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BA9525"></stop>
          <stop offset="0.771" stopColor="#F2D58A"></stop>
          <stop offset="1" stopColor="#CFCEB8"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_352_415"
          x1="114"
          x2="101.797"
          y1="20"
          y2="7.43"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BA9525"></stop>
          <stop offset="0.771" stopColor="#F2D58A"></stop>
          <stop offset="1" stopColor="#CFCEB8"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_352_415"
          x1="103"
          x2="90.797"
          y1="28"
          y2="15.43"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BA9525"></stop>
          <stop offset="0.771" stopColor="#F2D58A"></stop>
          <stop offset="1" stopColor="#CFCEB8"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear_352_415"
          x1="36"
          x2="23.797"
          y1="15"
          y2="27.57"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BA9525"></stop>
          <stop offset="0.771" stopColor="#F2D58A"></stop>
          <stop offset="1" stopColor="#CFCEB8"></stop>
        </linearGradient>
        <linearGradient
          id="paint8_linear_352_415"
          x1="47"
          x2="34.797"
          y1="23"
          y2="35.57"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BA9525"></stop>
          <stop offset="0.771" stopColor="#F2D58A"></stop>
          <stop offset="1" stopColor="#CFCEB8"></stop>
        </linearGradient>
        <linearGradient
          id="paint9_linear_352_415"
          x1="76.266"
          x2="72.906"
          y1="28"
          y2="29.984"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BA9525"></stop>
          <stop offset="0.771" stopColor="#F2D58A"></stop>
          <stop offset="1" stopColor="#CFCEB8"></stop>
        </linearGradient>
        <linearGradient
          id="paint10_linear_352_415"
          x1="58.266"
          x2="54.906"
          y1="28"
          y2="29.984"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BA9525"></stop>
          <stop offset="0.771" stopColor="#F2D58A"></stop>
          <stop offset="1" stopColor="#CFCEB8"></stop>
        </linearGradient>
        <linearGradient
          id="paint11_linear_352_415"
          x1="68.398"
          x2="63.359"
          y1="28"
          y2="30.976"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BA9525"></stop>
          <stop offset="0.771" stopColor="#F2D58A"></stop>
          <stop offset="1" stopColor="#CFCEB8"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Legend;
