import type { EventEmitter } from "eventemitter3";

export interface Profile {
  name: string | null;
  avatar: string | null;
}

export interface ProfileEvents {
  changed(profile: Profile): void;
}

export interface ProfileProvider extends Profile, EventEmitter<ProfileEvents> {}

export interface ProfileWindow extends Window {
  exodus?: {
    profile: ProfileProvider;
  };
}

declare const window: ProfileWindow;

/**
 * Returns the Exodus Profile provider.
 *
 * @returns {object|null} The Exodus Profile provider, or `null` if not available.
 */
export function getProfileProvider() {
  return window.exodus?.profile ?? null;
}
