import Icon from "../../../../components/Icon/Icon";
import Typography from "../../../../components/Typography/Typography";
import useI18n from "../../../../hooks/useI18n";
import { styled } from "../../../../stitches.config";
import { KycStatus } from "../../../../types";

const Wrapper = styled("div", {
  height: 130,
  width: "100%",
  marginTop: 30,
  borderRadius: 15,
  padding: "25px 30px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  background:
    "linear-gradient(180.98deg, rgba(255, 255, 255, 0.07) -24.21%, rgba(255, 255, 255, 0.0175) 116.35%)",
  boxShadow:
    "0px 2px 4px rgba(0, 0, 0, 0.12), inset 0px 1px 2px rgba(255, 255, 255, 0.04)",

  "@lg": {
    width: 220,
    marginTop: 0,
  },
});

const Text = styled("p", {
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 14,
  display: "flex",
  flexDirection: "column",
});

const statusButton: { [key: string]: string } = {
  inprogress: "Pending",
  processing: "Pending",
  rejected: "Rejected",
  expired: "Expired",
  "updates-required": "Update needed",
  "manual-review": "Manual review",
};

const StatusWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
});

const StatusLink = styled(Typography, {
  marginLeft: 10,
  cursor: "pointer",
  "&:hover": {
    opacity: 1,
  },
});

type KycDetailsProps = {
  kycStatus: KycStatus;
  onKycDetailsClick: React.MouseEventHandler<HTMLParagraphElement>;
};

const KycDetails = ({ kycStatus, onKycDetailsClick }: KycDetailsProps) => {
  const { t } = useI18n()
  const buttonText = statusButton[kycStatus] || statusButton.processing;
  const iconType = ["inprogress", "processing"].includes(kycStatus)
    ? "verification-pending"
    : "verification-warning";

  return (
    <Wrapper>
      <Text>{t("Verification")}</Text>
      <StatusWrapper>
        <Icon type={iconType}></Icon>
        <StatusLink onClick={onKycDetailsClick}>{buttonText}</StatusLink>
      </StatusWrapper>
    </Wrapper>
  );
};

export default KycDetails;
