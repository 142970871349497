import React from "react";

import { ReferrerLevel } from "../../types";

const COLORS = {
  enthusiast: ["#0E8F79", "#61C292"],
  trailblazer: ["#9CA1A8", "#F7FBFF"],
  champion: ["#CC8F14", "#E6CF8A"],
  sage: ["#556FF2", "#00BFFF"],
  legend: ["#8C66FF", "#6619FF"],
};

const Outline = ({ level }: { level: ReferrerLevel }) => {
  return (
    <svg
      width="calc(100% - 18px)"
      height="calc(100% - 18px)"
      fill="none"
      viewBox="0 0 112 112"
      style={{ position: "absolute", top: 9, left: 9 }}
    >
      <rect
        width="110"
        height="110"
        x="1"
        y="1"
        stroke={`url(#paint0_linear_352_381_${level})`}
        strokeWidth="2"
        rx="30"
      ></rect>
      <defs>
        <linearGradient
          id={`paint0_linear_352_381_${level}`}
          x1="111"
          x2="1"
          y1="111"
          y2="1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={COLORS[level][0]}></stop>
          <stop offset="1" stopColor={COLORS[level][1]}></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Outline;
