import { styled } from "../../../../stitches.config";

import UserBadge from "../../../../components/UserBadge";
import { ReferrerLevel } from "../../../../types";
import useI18n from "../../../../hooks/useI18n";

const Wrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",

  "@lg": {
    flexDirection: "row",
  },
});

const Text = styled("p", {
  display: "flex",
  flexDirection: "column",
  fontStyle: "normal",
  alignItems: "center",
  fontWeight: 400,
  marginTop: 20,

  "@lg": {
    marginTop: 0,
    marginLeft: 30,
    alignItems: "flex-start",
  },
});

const Welcome = styled("span", {
  fontSize: 14,
  color: "rgba(255, 255, 255, 0.4)",
});

const Name = styled("span", {
  fontSize: 24,
  color: "#FFFFFF",
});

type ProfileDetailsProps = {
  name: string | null;
  avatar: string | null;
  referrerLevel: ReferrerLevel;
};

const ProfileDetails = ({
  name,
  avatar,
  referrerLevel,
}: ProfileDetailsProps) => {
  const { t } = useI18n();

  return (
    <Wrapper>
      <UserBadge name={name} avatar={avatar} level={referrerLevel} />

      <Text>
        <Welcome>{t("Welcome")}</Welcome>
        <Name>{name || t("Exodus Friend")}</Name>
      </Text>
    </Wrapper>
  );
};

export default ProfileDetails;
