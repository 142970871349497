import type { CSSProperties, ReactNode } from "react";

import { styled } from "../../stitches.config";

const H1 = styled("h1", {
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: 40,
  lineHeight: '50px',

  '@lg': {
    fontSize: 48,
  },

  variants: {
    center: {
      true: { textAlign: 'center' },
    },
  },
});


const Heading = ({
  level,
  center,
  style,
  children,
}: {
  level: number;
  center?: boolean;
  style?: CSSProperties;
  children: ReactNode | string;
}) => {
  if (level === 1) return <H1 center={center} style={style}>{children}</H1>;

  console.warn(`Heading: level ${level} not yet implemented`);

  return null;
};

export default Heading;
