import type { EventEmitter } from "eventemitter3";

export interface AlgorandEvents {
  connect(address: string): void;
  disconnect(): void;
}

interface ConnectionOptions {
  onlyIfTrusted?: boolean;
}

interface ConnectInfo {
  address: string;
}

export interface AlgorandProvider extends EventEmitter<AlgorandEvents> {
  isConnected: boolean;
  address: string | null;
  connect(options?: ConnectionOptions): Promise<ConnectInfo>;
  disconnect(): Promise<void>;
  signMessage(
    encodedMessage: Uint8Array
  ): Promise<{ signature: Uint8Array; address: string }>;
}

export interface AlgorandWindow extends Window {
  exodus?: {
    algorand: AlgorandProvider;
    internal?: {
      getReferralsAuthToken?: () => Promise<string>; // TODO: use regular algorand provider when mobile supports it
      openExodusKyc?: () => Promise<void>;
      copyReferralCode?: () => Promise<void>;
      getLocales?: () => { locale?: string; currency?: string };
    };
  };
}

declare const window: AlgorandWindow;

/**
 * Returns the Exodus Algorand provider.
 *
 * @returns {object|null} The Exodus Algorand provider object, or `null` if not available.
 */
export function getAlgorandProvider() {
  return window.exodus?.algorand ?? null;
}

export function getExodusInternalProvider() {
  return window.exodus?.internal ?? null;
}

export function getExodusLocales() {
  return window.exodus?.internal?.getLocales?.() ?? {};
}