import React from "react";

import { AuthProvider } from "./AuthContext";
import { FetcherProvider } from "./FetcherContext";
import { WalletProvider } from "./WalletContext";
import { ProfileProvider } from "./ProfileContext";
import { I18nProvider } from "./I18nContext";

export default function AppContext({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <I18nProvider>
      <WalletProvider>
        <ProfileProvider>
          <AuthProvider>
            <FetcherProvider>{children}</FetcherProvider>
          </AuthProvider>
        </ProfileProvider>
      </WalletProvider>
    </I18nProvider>
  );
}
