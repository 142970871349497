import { styled } from "../../stitches.config";

const Wrapper = styled("div", {
  width: "100%",
  height: 8,
  borderRadius: 4,
  overflow: 'hidden',
  background: "rgba(255, 255, 255, 0.1)",
});

const Bar = styled("div", {
  height: "100%",
  borderRadius: 4,
  boxShadow: "none",
  backgroundSize: "20px 20px",
  backgroundImage:
    "linear-gradient(135deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)",
});

type ProgressBarProps = {
  max: number;
  value: number;
  color: string;
};

const ProgressBar = ({ max, value, color = "#61C2B2" }: ProgressBarProps) => {
  return (
    <Wrapper role="progressbar">
      <Bar style={{ width: `${(value / max) * 100}%`, backgroundColor: color }} />
    </Wrapper>
  );
};

export default ProgressBar;
