// @ts-ignore
import I18n from "@exodus/i18n/client/i18n.js";
import { createContext, ReactNode, useEffect, useMemo, useState } from "react";

import locales from "../locales";
import { getExodusLocales } from "../utils/wallet";

export type ProviderContextType = {
  i18n: object;
  t: (id: any, options?: { values: any; punctuations: any }) => string;
  locale?: string | null;
  currency?: string | null;
};

const I18nContext = createContext<ProviderContextType | null>(null);

const defaultCurrency = "USD";
const defaultLanguage = "en";

export function I18nProvider({ children }: { children: ReactNode }) {
  const [locale, setLocale] = useState<string | null>();
  const [currency, setCurrency] = useState<string | null>();

  const i18n = useMemo(
    () => new I18n({ defaultCurrency, defaultLanguage }),
    []
  );

  useEffect(() => {
    const getLocales = async () => {
      const locales = await getExodusLocales();
      setLocale(locales.locale);
      setCurrency(locales?.currency);
    };

    if (!locale) getLocales();
  });

  useEffect(() => {
    i18n.load(locales);

    i18n.setLanguage(locale);
  }, [i18n, locale]);

  const contextValue = useMemo(
    () => ({
      i18n,
      t: i18n.translate,
      locale: locale,
      currency: currency,
    }),
    [currency, i18n, locale]
  );

  return (
    <I18nContext.Provider value={contextValue}>{children}</I18nContext.Provider>
  );
}

export default I18nContext;
