import { useState } from "react";

export default function useLocalStorage<S>(
  key: string,
  initialState: S
): [S, (value: S) => void] {
  const [storedValue, setStoredValue] = useState<S>(() => {
    if (typeof window === "undefined") {
      return initialState;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialState;
    } catch (error) {
      // Ignore error.
      return initialState;
    }
  });

  const setValue = (value: S) => {
    try {
      setStoredValue(value);

      if (typeof window !== "undefined") {
        if (value === null) {
          window.localStorage.removeItem(key);
        } else {
          window.localStorage.setItem(key, JSON.stringify(value));
        }
      }
    } catch (error) {
      // TODO: Handle error.
    }
  };

  return [storedValue, setValue];
}
