import { styled } from "../../stitches.config";

import { ReferrerLevel } from "../../types";
import { getBlockie } from "../../utils/blockies";
import LevelInsignia from "../LevelInsignia";
import DefaultAvatar from "./DefaultAvatar";
import ImageAvatar from "./ImageAvatar";
import Outline from "./Outline";

const Wrapper = styled("div", {
  padding: 20,
  borderRadius: 40,
  position: "relative",
  boxSizing: "border-box",
  boxShadow:
    "0px 2px 4px rgba(0, 0, 0, 0.12), inset 0px 1px 2px rgba(255, 255, 255, 0.04)",
  background:
    "linear-gradient(180.98deg, rgba(255, 255, 255, 0.07) -24.21%, rgba(255, 255, 255, 0.0175) 116.35%)",
});

const Footer = styled("div", {
  position: "absolute",
  bottom: 0,
  left: "50%",
  transform: "translateX(-50%)",

  variants: {
    level: {
      enthusiast: { bottom: -12 },
      trailblazer: { bottom: -18 },
      champion: { bottom: -16 },
      sage: { bottom: -18 },
      legend: { bottom: -18 },
    },
  },
});

type UserBadgeProps = {
  size?: number;
  name: string | null;
  avatar: string | null;
  level: ReferrerLevel;
};

const UserBadge = ({ size = 130, name, avatar, level }: UserBadgeProps) => {
  const src = avatar ?? (name ? getBlockie(name).toDataURL() : undefined);

  return (
    <Wrapper style={{ width: size, height: size }}>
      <Outline level={level} />

      {src ? <ImageAvatar src={src} /> : <DefaultAvatar />}

      <Footer level={level}>
        <LevelInsignia level={level} />
      </Footer>
    </Wrapper>
  );
};

export default UserBadge;
