import type { CSSProperties } from "react";

type TrailblazerProps = {
  style?: CSSProperties;
};

const Trailblazer = ({ style }: TrailblazerProps) => {
  return (
    <svg width="56" height="24" fill="none" viewBox="0 0 56 24" style={style}>
      <path
        fill="url(#paint0_linear_352_391)"
        d="M35.541 6.058a.5.5 0 01.918 0l1.596 3.681a.5.5 0 00.411.299l3.995.38a.5.5 0 01.283.873l-3.008 2.656a.5.5 0 00-.157.483l.873 3.917a.5.5 0 01-.743.539l-3.455-2.04a.5.5 0 00-.508 0l-3.455 2.04a.5.5 0 01-.743-.54l.873-3.916a.5.5 0 00-.157-.483l-3.008-2.656a.5.5 0 01.283-.873l3.995-.38a.5.5 0 00.411-.299l1.596-3.681z"
      ></path>
      <path
        fill="url(#paint1_linear_352_391)"
        d="M19.541 6.058a.5.5 0 01.918 0l1.596 3.681a.5.5 0 00.411.299l3.995.38a.5.5 0 01.283.873l-3.008 2.656a.5.5 0 00-.157.483l.873 3.917a.5.5 0 01-.743.539l-3.455-2.04a.5.5 0 00-.508 0l-3.455 2.04a.5.5 0 01-.743-.54l.873-3.916a.5.5 0 00-.157-.483l-3.008-2.656a.5.5 0 01.283-.873l3.995-.38a.5.5 0 00.411-.299l1.596-3.681z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_352_391"
          x1="44"
          x2="28"
          y1="21"
          y2="5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9CA1A8"></stop>
          <stop offset="1" stopColor="#F7FBFF"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_352_391"
          x1="28"
          x2="12"
          y1="21"
          y2="5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9CA1A8"></stop>
          <stop offset="1" stopColor="#F7FBFF"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Trailblazer;
