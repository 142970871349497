import { styled } from "../../stitches.config";

const Button = styled("button", {
  padding: "15px 35px",
  borderRadius: 25,
  background: "linear-gradient(275.14deg, #420BE6 0%, #A16BB3 100%)",
  border: "none",
  textTransform: "uppercase",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 12,
  textAlign: "center",
  cursor: "pointer",
  boxShadow: `inset 0px 0px 20px rgba(255, 255, 255, 0.1)`,
  whiteSpace: "nowrap",

  '&:hover': {
    boxShadow: `inset 0px 0px 20px rgba(255, 255, 255, 0.25)`,
  },

  variants: {
    disabled: {
      true: {
        opacity: 0.6,
      }
    }
  }
});

export default Button;
