import type { CSSProperties } from "react";

type EnthusiastProps = {
  style?: CSSProperties
}

const Enthusiast = ({ style }: EnthusiastProps) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" style={style}>
      <g clipPath="url(#clip0_352_129)">
        <path
          fill="url(#paint0_linear_352_129)"
          d="M18.917 1.083c-.167-.167-.417-.25-.75-.25-.334.083-9.25 1.583-12.917 5.25C2.667 8.666 2.5 11.5 2.917 13.5L6 11.666c.417-.25.833.334.5.667l-3.083 3.083-.584.584L1 17.833A.806.806 0 001 19c.25.083.417.166.667.166.25 0 .416-.083.583-.25l2.333-2.333c.667.333 1.917.75 3.5.75 2.167 0 4.167-.833 5.834-2.5 4-4 5.166-12.667 5.25-13.083 0-.25-.084-.5-.25-.667z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_352_129"
          x1="19.167"
          x2="0.834"
          y1="19.166"
          y2="0.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0E798F"></stop>
          <stop offset="1" stopColor="#61C2B2"></stop>
        </linearGradient>
        <clipPath id="clip0_352_129">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Enthusiast;
