import React from "react";
import Button from "../../components/Button/Button";
import Heading from "../../components/Heading";
import Typography from "../../components/Typography";
import useI18n from "../../hooks/useI18n";
import useSignInWithWallet from "../../hooks/useSignInWithWallet";
import useWallet from "../../hooks/useWallet";
import { styled } from "../../stitches.config";
import TilesIcon from "./components/TilesIcon";

const Wrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const Content = styled("div", {
  maxWidth: 335,
  marginTop: 40,
  marginBottom: 40,

  "@lg": {
    maxWidth: "initial",
  },
});

type ConnectProps = {
  signingIn: boolean;
};

const ENABLE_CONNECT = false;

const Connect = ({ signingIn }: ConnectProps) => {
  const { installed } = useWallet();
  const { t } = useI18n();

  const { signIn } = useSignInWithWallet();

  const handleInstallClick = () => {
    window.open(
      "https://www.exodus.com/web3-wallet?utm_source=referrals_dashboard&utm_campaign=desktop_exodex_supported_pair_promo",
      "_blank"
    );
  };

  return (
    <Wrapper>
      <TilesIcon style={{ width: "100%" }} />

      <Content>
        <Heading level={1} center>
          {installed ? t("Connect Web3 Wallet") : t("Install Exodus")}
        </Heading>

        <Typography center style={{ marginTop: 20 }}>
          {t(
            "Your Invite Friends dashboard will show your referrals, payments, invite code, and more"
          )}
        </Typography>
      </Content>

      {ENABLE_CONNECT && installed && (
        <Button disabled={signingIn} style={{ width: 180 }} onClick={signIn}>
          {signingIn ? t("Connecting...") : t("Connect Exodus")}
        </Button>
      )}

      {!installed && (
        <Button onClick={handleInstallClick}>{t("Install Exodus")}</Button>
      )}
    </Wrapper>
  );
};

export default Connect;
