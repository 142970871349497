import { styled } from "../../../../stitches.config";

import type { Profile } from "../../../../utils/profile";
import { getExodusInternalProvider } from "../../../../utils/wallet";
import { User } from "../../../../hooks/useUser";
import LevelProgress from "./LevelProgress";
import ProfileDetails from "./ProfileDetails";
import KycDetails from "./KycDetails";
import KycStart from "./KycStart";
import SwapReward from "./SwapReward";

const Wrapper = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  marginBottom: 30,
  alignItems: "center",
  justifyContent: "space-between",
  gap: 10,

  "@lg": {
    marginBottom: 60,
    flexDirection: "row",
    alignItems: "flex-start",
    gap: 13,
  },
});

const LeftSide = styled(Wrapper, { marginBottom: 0 });
const RightSide = styled(Wrapper, {
  marginBottom: 0,
});

type HeroProps = {
  user?: User;
  profile: Profile | null;
};

const Hero = ({ user, profile }: HeroProps) => {
  if (!user) return null;

  const { kycStatus, isReferee, isLegacyReferee, rewardableSwaps, swapLimit } =
    user;

  const isKycStarted = kycStatus !== "pending";
  const isKycVerified = kycStatus === "verified";

  const showKycStart = !isKycStarted;
  const showKycProgress = isKycStarted && !isKycVerified;

  const handleKycStart = async () => {
    const internalProvider = getExodusInternalProvider();
    if (internalProvider?.openExodusKyc) {
      internalProvider?.openExodusKyc();
    }
  };

  const showRewardableSwaps = isReferee && !isLegacyReferee && rewardableSwaps;
  const showProfile = profile && (!showRewardableSwaps || isKycVerified);

  return (
    <Wrapper>
      {showProfile && (
        <LeftSide>
          <ProfileDetails
            name={profile.name}
            avatar={profile.avatar}
            referrerLevel={user.referrerLevel}
          />
        </LeftSide>
      )}

      <RightSide
        style={{ justifyContent: showProfile ? "flex-end" : "space-between" }}
      >
        {!!showRewardableSwaps && (
          <SwapReward rewardableSwaps={rewardableSwaps} swapLimit={swapLimit} />
        )}
        {showKycStart && <KycStart onKycStart={handleKycStart} />}
        {showKycProgress && (
          <KycDetails
            kycStatus={kycStatus}
            onKycDetailsClick={handleKycStart}
          />
        )}

        <LevelProgress
          refereeCount={user.refereeCount}
          referrerLevel={user.referrerLevel}
        />
      </RightSide>
    </Wrapper>
  );
};

export default Hero;
