import { CSSProperties, useState } from "react";
import Button from "../../../../components/Button";
import Typography from "../../../../components/Typography";
import { styled } from "../../../../stitches.config";
import ArrowIcon from "./ArrowIcon";
import ReferralsIcon from "./ReferralsIcon";
import { getExodusInternalProvider } from "../../../../utils/wallet";
import useI18n from "../../../../hooks/useI18n";

const Wrapper = styled("div", {
  width: "100%",
  padding: "20px 30px 30px 20px",
  borderRadius: 15,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background:
    "linear-gradient(180.98deg, rgba(255, 255, 255, 0.07) -24.21%, rgba(255, 255, 255, 0.0175) 116.35%)",
  boxShadow:
    "0px 2px 4px rgba(0, 0, 0, 0.12), inset 0px 1px 2px rgba(255, 255, 255, 0.04)",

  "@lg": {
    height: 140,
    padding: "0 30px 0 20px",
    flexDirection: "row",
  },
});

const Row = styled("div", {
  display: "flex",
  alignItems: "center",
});

const Text = styled(Typography, {
  flex: 1,
  opacity: 1,
  fontSize: 14,
  fontWeight: 400,
  marginLeft: 5,

  "@lg": {
    fontSize: 16,
    marginLeft: 0,
    marginRight: 6,
    width: 270,
  },
});

const Arrow = styled(ArrowIcon, {
  flex: 1,
  display: "none",

  "@lg": {
    display: "initial",
  },
});

const Code = styled("p", {
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: 40,
  letterSpacing: 10,
  color: "#FFFFFF",
  textAlign: "center",
  marginBottom: 16,

  "@lg": {
    marginBottom: 0,
    textAlign: "right",
    margin: "0 40px 0 18px",
  },
});

const Copy = styled(Button, {
  width: 180,
  placeSelf: "center",
});

type CodeBannerProps = {
  code?: string;
  style?: CSSProperties;
};

const CodeBanner = ({ code, style }: CodeBannerProps) => {
  const [copied, setCopied] = useState(false);
  const { t } = useI18n();

  const createShareMessage = (code: string) => {
    const referralUrl = `https://www.exodus.com/download?referral=true&referrerId=${encodeURIComponent(
      code
    )}`;

    return t(
      `Hi, try out Exodus and manage all your crypto in one secure wallet. Enter my invite code ${code} during setup. 🚀 \n${referralUrl}`
    );
  };

  const handleCopyClick = () => {
    if (!code) return;

    const internalProvider = getExodusInternalProvider();
    if (internalProvider?.copyReferralCode) {
      internalProvider?.copyReferralCode();
    } else {
      navigator.clipboard.writeText(createShareMessage(code));
    }

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return (
    <Wrapper style={style}>
      <Row>
        <ReferralsIcon />

        <Text>
          {t(
            "Share your code and earn each time a referred friend completes a swap on qualifying asset pairs"
          )}
        </Text>
      </Row>

      <Arrow />

      <Code>{code}</Code>

      <Copy disabled={copied} onClick={handleCopyClick}>
        {copied ? t("Copied") : t("Copy")}
      </Copy>
    </Wrapper>
  );
};

export default CodeBanner;
