import React from "react";
import type { CSSProperties } from "react";

const ExodusLogo = ({ style }: { style?: CSSProperties }) => {
  return (
    <svg
      width="140"
      height="29"
      fill="none"
      viewBox="0 0 140 29"
      style={style}
    >
      <mask
        id="mask0_169_3962"
        style={{ maskType: "alpha" }}
        width="29"
        height="29"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#1D1D1B"
          d="M28.576 8.027L16.334 0v4.488l7.853 5.103-.924 2.923h-6.93v3.719h6.93l.924 2.923-7.853 5.103v4.488l12.242-8-2.002-6.36 2.002-6.36zM5.683 16.233h6.904v-3.719h-6.93L4.76 9.591l7.828-5.103V0L.345 8.027l2.002 6.36-2.002 6.36 12.267 8V24.26L4.76 19.156l.924-2.923z"
        ></path>
      </mask>
      <g mask="url(#mask0_169_3962)">
        <path
          fill="#fff"
          d="M28.576 8.027L16.334 0v4.488l7.853 5.103-.924 2.923h-6.93v3.719h6.93l.924 2.923-7.853 5.103v4.488l12.242-8-2.002-6.36 2.002-6.36zM5.683 16.233h6.904v-3.719h-6.93L4.76 9.591l7.828-5.103V0L.345 8.027l2.002 6.36-2.002 6.36 12.267 8V24.26L4.76 19.156l.924-2.923z"
        ></path>
        <path
          fill="url(#paint0_linear_169_3962)"
          d="M0.381 0H31.627V31.793H0.381z"
        ></path>
        <ellipse
          cx="2.093"
          cy="6.304"
          fill="url(#paint1_radial_169_3962)"
          rx="27.484"
          ry="29.798"
          transform="rotate(-33.93 2.093 6.304)"
        ></ellipse>
      </g>
      <path
        fill="#fff"
        d="M52.93 7.905v2.513H41.348v2.572h9.841v2.513h-9.84v2.825H52.93v2.514H38.377V7.905H52.93zM54.573 20.841l6.654-6.546-6.465-6.39h4.09l4.562 4.618 4.373-4.618h3.827l-6.447 6.39 6.654 6.546h-4.128l-4.279-4.753-4.882 4.753h-3.959z"
      ></path>
      <path
        fill="#fff"
        d="M79.8 7.905c5.392 0 8.634 2.7 8.634 6.468 0 3.769-3.242 6.469-8.634 6.469-5.39 0-8.614-2.7-8.614-6.469 0-3.768 3.223-6.468 8.614-6.468zm0 2.419c-3.242 0-5.632 1.63-5.632 4.05 0 2.418 2.39 4.049 5.632 4.049 3.261 0 5.651-1.631 5.651-4.05 0-2.418-2.39-4.05-5.65-4.05zM99.18 7.905c4.318 0 6.92 2.397 6.92 6.449 0 4.091-2.583 6.488-6.882 6.488h-8.75V7.905h8.712zm3.865 6.449c0-2.533-1.508-3.935-4.242-3.935h-5.374v7.91h5.374c2.734 0 4.242-1.423 4.242-3.975zM115.892 20.841c-4.547 0-7.996-2.063-7.996-5.866v-7.07h2.975v6.784c0 2.35 2.444 3.44 5.021 3.44 2.595 0 5.039-1.07 5.039-3.44V7.905h2.956v7.07c0 3.803-3.429 5.867-7.995 5.867zM132.213 20.841c-2.443 0-5.076-.45-6.946-1.293l.973-2.4c1.679.769 3.988 1.256 6.069 1.256 2.385 0 4.618-.506 4.618-1.406 0-.656-.706-.937-2.176-1.162l-4.16-.45c-3.206-.431-4.771-1.538-4.771-3.487 0-2.513 2.653-3.994 6.546-3.994 2.347 0 5.572.431 7.233 1.181l-.974 2.288c-1.641-.675-4.427-1.013-6.393-1.013-2.023 0-3.359.469-3.359 1.331 0 .6.668.9 2.386 1.144l4.046.45c3.091.431 4.695 1.481 4.695 3.525 0 2.587-3.397 4.03-7.787 4.03z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_169_3962"
          x1="24.673"
          x2="16.44"
          y1="30.866"
          y2="-2.979"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B46F9"></stop>
          <stop offset="1" stopColor="#BBFBE0"></stop>
        </linearGradient>
        <radialGradient
          id="paint1_radial_169_3962"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(6.8709 21.47207 -20.12724 6.44057 2.093 6.304)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.12" stopColor="#8952FF" stopOpacity="0.87"></stop>
          <stop offset="1" stopColor="#DABDFF" stopOpacity="0"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
};

export default ExodusLogo;
