import type { ReactElement, ReactNode } from "react";

import { styled } from "../../stitches.config";

import StatNumber from "./StatNumber";

const Wrapper = styled("div", {
  width: "100%",
  height: 240,
  borderRadius: 20,
  display: "flex",
  position: "relative",
  flexDirection: "column",
  boxShadow:
    "0px 2px 4px rgba(0, 0, 0, 0.12), inset 0px 1px 2px rgba(255, 255, 255, 0.1)",

  variants: {
    color: {
      purple: {
        background: "linear-gradient(315deg, #5C26FF 0%, #8C66FF 100%)",
      },
      skyblue: {
        background:
          "linear-gradient(0deg, #53C0CF, #53C0CF), linear-gradient(180.04deg, #D8D9CE 0.08%, #A5A695 99.92%);",
      },
      blue: {
        background: "linear-gradient(315deg, #556FF2 0%, #00BFFF 100%);",
      },
      green: {
        background: "linear-gradient(152.68deg, #2E5D61 0%, #224548 100%);",
      },
    },
  },

  "@lg": {
    height: 320,
  },
});

const Icon = styled("div", {
  position: "absolute",
  top: 10,
  left: 10,

  "@lg": {
    top: 15,
    left: 15,
  },
});

const Content = styled("div", {
  flex: 1,
  display: "flex",
  paddingTop: 20,
  justifyContent: "center",

  "@lg": {
    paddingTop: 40,
  },
});

const Stroke = styled("div", {
  boxSizing: "border-box",
  position: "absolute",
  width: 140,
  height: 140,
  borderRadius: "50%",
  borderStyle: "solid",
  borderColor: "rgba(0, 0, 0, 0.1)",
  boxShadow: "inset 0px 2px 12px rgba(0, 0, 0, 0.1)",
  borderWidth: 12,

  "@lg": {
    width: 190,
    height: 190,
    borderWidth: 16,
  },
});

const StatBox = styled("div", {
  width: 116,
  height: 116,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  borderRadius: "50%",
  boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.4)",
  background:
    "linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0.2) 100%)",

  "@lg": {
    width: 158,
    height: 158,
  },
});

const Unit = styled("p", {
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 13,
  textAlign: "center",
  color: "#FFFFFF",
  opacity: 0.8,
});

const Footer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: 60,
  borderTop: "1px solid rgba(255, 255, 255, 0.08)",

  "@lg": {
    height: 75,
  },
});

const Title = styled("p", {
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 15,
  textAlign: "center",
  color: "#FFFFFF",
});

const Subtitle = styled(Title, {
  opacity: 0.6,
});

const EmptyStatDetail = styled("div", {
  textAlign: "center",
  background: "rgba(255, 255, 255, 0.1)",
  height: 5,

  variants: {
    size: {
      sm: { width: "40%" },
      lg: { width: "60%" },
    },
  },
});

type color = "purple" | "skyblue" | "blue" | "green";

type StatTileProps = {
  title: ReactNode | string;
  subtitle?: string;
  value: number | null;
  unit?: string;
  icon?: ReactElement;
  color: color;
};

export const EmptyStatTile = ({ color }: { color: color }) => {
  return (
    <Wrapper color={color}>
      <Content>
        <Stroke>
          <StatBox>
            <EmptyStatDetail size="sm" />
          </StatBox>
        </Stroke>
      </Content>

      <Footer>
        <EmptyStatDetail size="lg" />
      </Footer>
    </Wrapper>
  );
};

const StatTile = ({
  title,
  subtitle,
  value,
  unit,
  icon,
  color,
}: StatTileProps) => {
  return (
    <Wrapper color={color}>
      {icon && <Icon>{icon}</Icon>}

      <Content>
        <Stroke>
          <StatBox>
            <StatNumber value={value} size={unit ? "sm" : "lg"} />
            {unit && <Unit>{unit}</Unit>}
          </StatBox>
        </Stroke>
      </Content>

      <Footer>
        <Title>{title}</Title>

        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Footer>
    </Wrapper>
  );
};

export default StatTile;
