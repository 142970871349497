import type { CSSProperties } from "react";

type ChampionProps = {
  style?: CSSProperties;
};

const Champion = ({ style }: ChampionProps) => {
  return (
    <svg width="56" height="24" fill="none" viewBox="0 0 56 24" style={style}>
      <path
        fill="url(#paint0_linear_352_58)"
        d="M27.541 1.058a.5.5 0 01.918 0l2.682 6.186a.5.5 0 00.412.299l6.712.64a.5.5 0 01.283.872l-5.054 4.463a.5.5 0 00-.157.483l1.466 6.582a.5.5 0 01-.742.539l-5.807-3.428a.5.5 0 00-.508 0l-5.806 3.428a.5.5 0 01-.743-.54l1.466-6.58a.5.5 0 00-.157-.484l-5.054-4.463a.5.5 0 01.283-.873l6.713-.64a.5.5 0 00.41-.298l2.683-6.186z"
      ></path>
      <path
        fill="url(#paint1_linear_352_58)"
        d="M47.541 6.058a.5.5 0 01.918 0l1.596 3.681a.5.5 0 00.411.299l3.995.38a.5.5 0 01.283.873l-3.008 2.656a.5.5 0 00-.157.483l.873 3.917a.5.5 0 01-.743.539l-3.455-2.04a.5.5 0 00-.508 0l-3.455 2.04a.5.5 0 01-.743-.54l.873-3.916a.5.5 0 00-.157-.483l-3.008-2.656a.5.5 0 01.283-.873l3.995-.38a.5.5 0 00.411-.299l1.596-3.681z"
      ></path>
      <path
        fill="url(#paint2_linear_352_58)"
        d="M7.541 6.058a.5.5 0 01.918 0l1.596 3.681a.5.5 0 00.411.299l3.995.38a.5.5 0 01.283.873l-3.008 2.656a.5.5 0 00-.157.483l.873 3.917a.5.5 0 01-.743.539l-3.455-2.04a.5.5 0 00-.508 0l-3.455 2.04a.5.5 0 01-.743-.54l.873-3.916a.5.5 0 00-.157-.483L1.256 11.29a.5.5 0 01.283-.873l3.995-.38a.5.5 0 00.411-.299l1.596-3.681z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_352_58"
          x1="40"
          x2="11.697"
          y1="24"
          y2="16.711"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BA9525"></stop>
          <stop offset="0.771" stopColor="#F2D58A"></stop>
          <stop offset="1" stopColor="#CFCEB8"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_352_58"
          x1="56"
          x2="37.131"
          y1="21"
          y2="16.141"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BA9525"></stop>
          <stop offset="0.771" stopColor="#F2D58A"></stop>
          <stop offset="1" stopColor="#CFCEB8"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_352_58"
          x1="16"
          x2="-2.869"
          y1="21"
          y2="16.141"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BA9525"></stop>
          <stop offset="0.771" stopColor="#F2D58A"></stop>
          <stop offset="1" stopColor="#CFCEB8"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Champion;
