import useI18n from "../../../../hooks/useI18n";
import Button from "../../../../components/Button/Button";
import Typography from "../../../../components/Typography/Typography";
import { styled } from "../../../../stitches.config";

const Wrapper = styled("div", {
  height: 175,
  width: "100%",
  borderRadius: 15,
  padding: "25px 30px",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "space-between",
  alignItems: "center",
  background:
    "linear-gradient(180.98deg, rgba(255, 255, 255, 0.07) -24.21%, rgba(255, 255, 255, 0.0175) 116.35%)",
  boxShadow:
    "0px 2px 4px rgba(0, 0, 0, 0.12), inset 0px 1px 2px rgba(255, 255, 255, 0.04)",

  "@lg": {
    flexWrap: "unset",
    width: 402,
    height: 130,
    marginTop: 0,
  },
});

const Divider = styled("div", {
  display: "none",
  width: 1,
  height: "100%",
  background: "rgba(255, 255, 255, 0.05);",
  marginLeft: 20,
  marginRight: 20,
  "@lg": {
    display: "flex",
  },
});

const LeftSide = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "@lg": {
    height: "100%",
  },
});

const VerifyButton = styled(Button, {
  width: "100%",
  height: 40,
  paddingTop: 10,
  paddingBottom: 10,
  fontSize: 11,
  lineHeight: "20px",
  "@lg": {
    display: "flex",
    width: 120,
  },
});

const DescriptionText = styled(Typography, {
  fontSize: 12,
  marginBottom: 20,
  marginTop: 15,
  lineHeight: "15px",
  "@lg": {
    marginBottom: 0,
  },
});

interface KycStartProps {
  onKycStart: React.MouseEventHandler<HTMLButtonElement>;
}

const KycStart = ({ onKycStart }: KycStartProps) => {
  const { t } = useI18n();

  return (
    <Wrapper>
      <LeftSide>
        <Typography style={{ opacity: 1, lineHeight: "20px" }}>
          {t("Verify Your Identity")}
        </Typography>
        <DescriptionText>
          {t(
            "After completing verification, you can get paid automatically every month"
          )}
        </DescriptionText>
      </LeftSide>

      <Divider />
      <VerifyButton onClick={onKycStart}>Verify</VerifyButton>
    </Wrapper>
  );
};

export default KycStart;
