import invariant from "tiny-invariant";
import { useContext } from "react";

import AuthContext from "../context/AuthContext";

export default function useAuth() {
  const context = useContext(AuthContext);
  invariant(
    typeof context !== "undefined",
    "useAuth must be used within an AuthProvider"
  );
  return context;
}
