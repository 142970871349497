import { styled } from "../../stitches.config";

const Typography = styled("p", {
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '25px',
  opacity: 0.4,

  variants: {
    center: {
      true: { textAlign: 'center' },
    },
  },
});

export default Typography;
