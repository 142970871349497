import useSWR from "swr";

import type { Reward } from "../types";

import useFetcher from "./useFetcher";

export default function useNextReward() {
  const fetcher = useFetcher();

  const { data, error, isLoading } = useSWR<Reward>(
    `/rewards/next`,
    fetcher,
    {
      refreshInterval: 5000,
    }
  );

  return {
    nextReward: data,
    loading: isLoading,
    error,
  };
}
