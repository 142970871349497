import de from "./de/messages.po";
import en from "./en/messages.po";
import es419 from "./es-419/messages.po";
import esES from "./es-ES/messages.po";
import fr from "./fr/messages.po";
import it from "./it/messages.po";
import nl from "./nl/messages.po";
import pt from "./pt/messages.po";
import ru from "./ru/messages.po";
import zh from "./zh/messages.po";

const locales = {
  de,
  en,
  "es-419": es419,
  "es-ES": esES,
  fr,
  it,
  nl,
  pt,
  ru,
  zh,
};

export default locales;
