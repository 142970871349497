type VerificationPendingProps = {
  size?: number;
};

const VerificationPending = ({ size = 24 }: VerificationPendingProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
    >
      <circle cx={12} cy={12} r={12} fill="url(#a)" />
      <g clipPath="url(#b)">
        <path
          fill="#fff"
          d="M8.5 6.167v3.5h.006l-.006.006L10.833 12 8.5 14.334l.006.005H8.5v3.495h7v-3.495h-.006l.006-.005L13.167 12 15.5 9.673l-.006-.006h.006v-3.5h-7Zm5.833 8.458v2.042H9.667v-2.042L12 12.292l2.333 2.333ZM12 11.71 9.667 9.375V7.334h4.666v2.041L12 11.71Z"
        />
      </g>
      <defs>
        <linearGradient
          id="a"
          x1={24}
          x2={0}
          y1={24}
          y2={0}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#556FF2" />
          <stop offset={1} stopColor="#00BFFF" />
        </linearGradient>
        <clipPath id="b">
          <path fill="#fff" d="M5 5h14v14H5z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VerificationPending;
