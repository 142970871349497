import { DateTime } from "luxon";

import { styled } from "../../../../stitches.config";
import Heading from "../../../../components/Heading";
import Table from "../../../../components/Table";
import Typography from "../../../../components/Typography";
import { useMemo } from "react";
import { Reward } from "../../../../types";
import useI18n from "../../../../hooks/useI18n";

const Wrapper = styled("div", {
  marginTop: 40,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",

  "@lg": {
    width: 770,
    marginTop: 80,
  },
});

const Subtitle = styled(Typography, {
  marginTop: 20,
  marginBottom: 40,
  marginLeft: 10,
  marginRight: 10,

  "@lg": {
    width: 570,
    marginBottom: 50,
    marginLeft: 0,
    marginRight: 0,
  },
});

type RewardsProps = {
  nextReward?: Reward;
  rewardHistory?: Reward[];
  loading: boolean;
};

const Rewards = ({ nextReward, rewardHistory, loading }: RewardsProps) => {
  const { t } = useI18n();

  const data = useMemo(() => {
    const data = [];

    if (nextReward) data.push(nextReward);
    if (rewardHistory) data.push(...rewardHistory);

    return data.map((reward) => ({
      reward: `${reward.amount.toFixed(2)} USDC`,
      payout: reward.paidOutAt ? `${reward.amount.toFixed(2)} USDC` : "-",
      month: DateTime.fromISO(reward.date).toFormat("LLLL, yyyy"),
    }));
  }, [nextReward, rewardHistory]);

  return (
    <Wrapper>
      <Heading level={1}>Your Rewards</Heading>

      <Subtitle center>
        {t(
          "When someone uses your invite code while setting up their Exodus, they’ll be listed below as a friend. Each time they complete a swap, you’ll earn rewards."
        )}
      </Subtitle>

      <Table
        columns={[
          { key: "month", title: "Month", align: "left" },
          { key: "reward", title: "Reward", align: "center" },
          { key: "payout", title: "Payout", align: "right" },
        ]}
        data={data}
        loading={loading}
      />
    </Wrapper>
  );
};

export default Rewards;
